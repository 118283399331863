import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
import { Form, Input, Button, message, Space, Typography, Col, Row, Select } from "antd";
import { registerUser } from '../../endpoints/register'
const { Title } = Typography;
var platform = require('platform');
const { Option } = Select;

const Register = ({ app, testStore, designPage, user, ...props }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [messages, setMessage] = useState('aucun');

    const success = (messagev) => {
        messageApi.open({
            type: 'success',
            content: messagev,
            style: {
                marginTop: '20vh',
            }
        });
    };

    const warning = (messagev) => {
        messageApi.open({
            type: 'danger',
            content: messagev,
            style: {
                marginTop: '20vh',
            }
        });
    };

    const onFinish = async (values) => {
        values.room = props.room
        values.platform = platform;
        setLoading(true);

        registerUser(values).then(resp => {
            if (resp.response) {
                success(resp.result)
              
                    setLoading(false);
                    console.log("Connexion réussie!", values);
                    props?.dispatch(setUserApp({ InitUserState: 'login', username: values.username, token: resp.token }))
               
            }
            else {
                warning(resp.result)
                setLoading(false);
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    const setLogin = () => {
        props?.dispatch(setUserApp({ InitUserState: 'login' }))
    }

    return (
        <>
              <Row style={{ backgroundColor: '#010103' }}>
                <Col span={24}> <Title level={3} style={{margin:12, textAlign: 'center', color: ' rgb(248 248 248)' }}>VIDEOCHAT RADIO</Title></Col>
            </Row>
            <div
                style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{backgroundColor:'black', padding: '60px', border: ' 0.5px solid', borderRadius: '16px' }}>
                    <div >

                        <Form
                            name="loginForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{ span: 12 }}
                        >
                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Nom d'utilisateur</span>}
                                name="username"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre nom d'utilisateur!" },
                                    { pattern: /^[a-zA-Z0-9]+$/, message: "Le nom d'utilisateur ne doit contenir que des lettres et des chiffres!" },
                                    { whitespace: true, message: "Le nom d'utilisateur ne peut pas contenir d'espaces vides!" }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Âge</span>}
                                name="age"
                                initialValue={30}
                                rules={[
                                    { required: true, message: 'Veuillez sélectionner votre âge!' },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>

                            <Form.Item
                                labelAlign="left"
                                name="gender"
                                label={<span style={{ color: 'white' }}>Gender</span>}
                                rules={[{
                                    required: true
                                }
                                ]}>
                                <Select
                                    placeholder="Select a option and change input text above"
                                    allowClear
                                >
                                    <Option value="male">male</Option>
                                    <Option value="female">female</Option>
                                    <Option value="other">other</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Adresse e-mail</span>}
                                name="email"
                                rules={[
                                    { required: true, message: 'Veuillez saisir votre adresse e-mail!' },
                                    { type: 'email', message: 'Veuillez saisir une adresse e-mail valide!' },
                                ]}
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Mot de passe</span>}
                                name="password"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre mot de passe!" }
                                ]}

                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Confirmer le mot de passe</span>}
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[
                                    { required: true, message: 'Veuillez confirmer votre mot de passe!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Les mots de passe ne correspondent pas!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>


                            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                                <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                                    S'enregistrer
                                </Button>
                            </Form.Item>

                            <Col span={24} style={{ justifyContent: 'center', fontSize: '13px' }}>

                                <span onClick={setLogin} style={{ color: 'white', fontSize: '13px' }}>Déja Inscrit ? <bold style={{ color: 'orange' }}>Se connecter</bold></span>
                            </Col>
                        </Form>
                        {contextHolder}
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(Register);
