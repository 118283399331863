const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

function playAudio(mp3FileName) {
    const audio = new Audio(`${URL}/systeme/sound/${mp3FileName}`);
    audio.play();
  }

  function playAudioGift(mp3FileName) {
    const audio = new Audio(`${mp3FileName}`);
    audio.play();
  }
  
export { playAudio, playAudioGift };
  