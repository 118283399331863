import { useContext, useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { ConversationList } from '@chatscope/chat-ui-kit-react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Radio, Tabs, Badge, Row, Col, Modal, Button, Input, Image } from 'antd';
import { SocketContext } from '../../SocketContext';

const ModalMessageFromServer = ({ user, ...props }) => {
   
    return (
        <>
            <Modal
                title="Message systeme"
                open={props.isModalOpen}
                onCancel={props.closeModal} 
                footer={null}
            >
                <Row >
                    <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
                     {props.message}
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModalMessageFromServer);
