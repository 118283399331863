export function sendGif(socket, user ,socketId , gift) {

    gift.sendFromUsername = user?.username
    return new Promise((resolve, reject) => {
        socket.emit("sendGift", {socketId : socketId  , gift : gift , token:user?.token_virtual}, (err, response) => {
            if (!err) {
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}


export function kick(socket, user ,socketId , roomId) {
    return new Promise((resolve, reject) => {
        socket.emit("kickUser", {socketId : socketId  , roomId : roomId}, (err, response) => {
            if (!err) {
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function ban(socket , user, userObj,roomId) {
    return new Promise((resolve, reject) => {
        socket.emit("banUser", {userObj : userObj  , roomId : roomId}, (err, response) => {
            if (!err) {
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function blockAudio(socket , user, socketid) {
    return new Promise((resolve, reject) => {
        socket.emit("block_audio", {socketId:socketid , roomId : user?.selectedRoom}, (err, response) => {
            if (!err) {
               
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function blockVideo(socket , user, socketid) {
    return new Promise((resolve, reject) => {
        socket.emit("block_video", {socketId:socketid , roomId : user?.selectedRoom}, (err, response) => {
            if (!err) {
              
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function unBlockAudio(socket , user, socketid) {
    return new Promise((resolve, reject) => {
        socket.emit("unBlock_audio", {socketId:socketid , roomId : user?.selectedRoom}, (err, response) => {
            if (!err) {
               
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function unBlockVideo(socket , user, socketid) {
    return new Promise((resolve, reject) => {
        socket.emit("unBlock_video", {socketId:socketid , roomId : user?.selectedRoom}, (err, response) => {
            if (!err) {
              
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function blockMessage(socket , user, userObj,roomId) {
    return new Promise((resolve, reject) => {
        socket.emit("blockMessage", userObj, (err, response) => {
            if (!err) {
             
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function unBlockMessage(socket , user, userObj,roomId) {
    return new Promise((resolve, reject) => {
        socket.emit("unBlockMessage", userObj, (err, response) => {
            if (!err) {
              
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
}

export function deleteMessageId(user,socket , messageId) {
    return new Promise((resolve, reject) => {
        socket.emit("eraseMessageId", messageId, user.indexRoom, (err, response) => {
            if (!err) {
              
                resolve(response);
            } else {
                reject(err);
            }
        });
    });
}

export function askQuestion(socket , array) {
    socket.emit("askQuestion", array);
  }

  export function invitePrivate(socket , user, userTarget , fromUser) {
    return new Promise((resolve, reject) => {
        socket.emit("invitePrivate", userTarget , fromUser, (err, response) => {
            if (!err) {
           
                resolve(response.text);
            } else {
                reject(err);
            }
        });
    });
  }

