import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { Col, Row, Modal, Button ,Spin } from 'antd';

const ModeDevShowUserObject = ({ user, ...props }) => {

    return (
        <Row style={{textAlign:'left' , backgroundColor:'black',color:'white',padding:'5px'}}>
            <Col style={{textAlign:'center',color:'yellow'}} span={24} >
               USER OBJECT DEV MODE <Spin />
            </Col>
            <Col span={24} >
               username :  {props.userinfos.username}
            </Col>

            <Col span={24} >
               socketID : {props.userinfos.socketId}
            </Col>

            <Col span={24} >
               Token: {props.userinfos.token}
            </Col>

            <Col span={24} >
            audioEnabled : {String(props.userinfos.audioEnabled)}
            </Col>

            <Col span={24} >
            audioBlocked : {String(props.userinfos.audioBlocked)}
            </Col>

            <Col span={24} >
            videoEnabled : {String(props.userinfos.videoEnabled)}
            </Col>

            <Col span={24} >
            videoBlocked : {String(props.userinfos.videoBlocked)}
            </Col>

            <Col span={24} >
            WatchingMe : {props.userinfos.WatchingMe.length}
            </Col>

            <Col span={24} >
              Role : {props.userinfos.role}
            </Col>

            <Col span={24} >
               Ip : {props.userinfos.ip}
            </Col>

            <Col span={24} >
              Status : {props.userinfos.status}
            </Col>

            <Col span={24} >
            blockUserMessage : {props.userinfos.blockUserMessage}
            </Col>
        </Row>
    )
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModeDevShowUserObject);
