import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { NotificationOutlined, SoundOutlined, MenuUnfoldOutlined, VideoCameraAddOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, SettingOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { } from '@chatscope/chat-ui-kit-react';
import { Col, Row, Modal, Radio, Button } from 'antd';
import { SocketContext } from '../../SocketContext';
import imageCompression from 'browser-image-compression';

import IndexAdminMenu from '../../adminMenu/Index'
import useRefs from '../../ref/useRefs'
import { avatar } from '../../../../endpoints/avatar'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalUserOptions = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [adminPanelVisible, setAdminPanelVisible] = useState(false)
    const [toggleSoundColorRef, setToggleSoundColorRef] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [status, setStatus] = useState('online');
    const socket = useContext(SocketContext);
    const userOpenModalRef = {}
    const userCloseModalRef = {}
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    let sizeOfImage = 99999999999999;
    const mounteImagedRef = useRef(false)

    const [videoEnableDisabled, setEnableVideoDisabled] = useState(false)
    const [audioEnableDisabled, setEnableAudioDisabled] = useState(false)

    const toggleEnvironementSound = () => {
        setToggleSoundColorRef(previous => !previous)
        ref.SoundEffect.current = !toggleSoundColorRef
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
            avatar(user?.token, user?.room, user?.socketId, formData).then(resp => {
                console.log(resp)
                props?.dispatch(setUserApp({ avatar: resp.avatar }))

            })
        } catch (error) {
            console.log(error)
        }
    }

   

    useEffect(() => {
        if (mounteImagedRef.current) {
            handleSubmit()
            mounteImagedRef.current = false
        }
    }, [selectedFile])

 


    const handleFileSelect = (event) => {

        const data = event.target.files[0]
        if (data.type === 'image/png' || data.type === 'image/jpeg' || data.type === 'image/webp') {
            const fileName = data.name
            const compress = async data => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    //message reader.error
                }
                await imageCompression(data, { maxSizeMB: 1, useWebWorker: true }).then(res => {
                    if ((res.size / 1024).toFixed(0) === sizeOfImage && sizeOfImage > 400) {
                        //retourne un message d'erreur
                    }
                    if (sizeOfImage >= 400) {
                        res.name = fileName
                        mounteImagedRef.current = true
                        setSelectedFile(res)

                    } else {
                        reader.readAsDataURL(res)
                    }
                    sizeOfImage = (res.size / 1024).toFixed(0);
                })
            }
            compress(data)
            event.target.value = ''
        }
        else if (data.type === 'image/gif') {
            mounteImagedRef.current = true
            setSelectedFile(data)
        }
        else {
            props?.dispatch(setMessageApp({ type: 'error', message: 'fichier refuser', notView: true }));
        }
    }
    
    const handleStatusChange = (value) => {
        setStatus(value.target.value);
        const userStatus = value.target.value
        socket.emit("updateStatus", userStatus)
    };
    /* main modal */
    const handleOk = () => {
        props.setIsModalOpen(false);
    };
    const handleCancel = () => {
        props.setIsModalOpen(false);
    };
    /* main modal */

    const showModal = () => {
        setIsModalOpen(true);
    };


    return (
        <>
            {/* admin menu */}
            <IndexAdminMenu ref={ref} adminPanelVisible={adminPanelVisible} setAdminPanelVisible={setAdminPanelVisible} />
            {/* admin menu */}

            <Modal title="Panneau de configuration" open={props.isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Row style={{ color: 'black', backgroundColor: 'white', padding: '5px' }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        Sons
                    </Col>

                    <Col span={12}>
                        <Button onClick={toggleEnvironementSound} border ref={null} style={{ fontSize: '26px', height: '50px' }} >{toggleSoundColorRef ? <SoundOutlined style={{color:toggleSoundColorRef && 'green' }} /> : <NotificationOutlined />}</Button>
                    </Col>
                </Row>
                <Row style={{ color: 'black', backgroundColor: 'white', padding: '5px' }}>

                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        Menu Admin
                    </Col>

                    <Col span={12} >
                        <Button onClick={() => setAdminPanelVisible(true)}>Ouvrir</Button>
                    </Col>
                </Row>

                <Row style={{ color: 'black', backgroundColor: 'white', padding: '5px' }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        Status
                    </Col>

                    <Col span={12}>
                        <div>
                            <Radio.Group onChange={handleStatusChange} value={status}>
                                <Radio.Button style={{ color:status === 'available' && 'green' }} value="available" >
                                    Online
                                </Radio.Button>
                                <Radio.Button style={{ color: status === 'unavailable' && 'orange' }} value="unavailable">
                                    Away
                                </Radio.Button>
                                <Radio.Button style={{ color:status === 'dnd' && 'red' }} value="dnd">
                                    Absent
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>


                <Row style={{ color: 'black', backgroundColor: 'white', padding: '5px' }}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        Envoyer un avatar
                    </Col>

                    <Col span={12}>
                        <input onChange={handleFileSelect} name='file' id="file-input" type="file" accept="image/png, image/jpeg" />
                    </Col>
                </Row>
            </Modal>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalUserOptions);
