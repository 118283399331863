import { MenuUnfoldOutlined, VideoCameraOutlined, MenuFoldOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, theme, Badge, Col, Row } from 'antd';
import { useState, useRef, forwardRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { SocketContext } from '../../SocketContext';
import PrivateJspanel from '../../privateChat/PrivateJspanel'
import useRefs from '../../ref/useRefs'

const PrivateTopLevel = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    let socket = useContext(SocketContext);
    const [newId, setNewId] = useState()
    const [idList, setIdList] = useState([]);

    const addPrivate = (id) => {
        setNewId(id)
        setIdList([...idList, id]);
    }

    useEffect(() => {
        if (socket) {
            socket.on('startPrivateJspanel', addPrivate);
        }

        return () => {
            if (socket) {
                socket.off('startPrivateJspanel', addPrivate);
            }
        };
    }, [socket]);

    const renderedUsers = (props.userlist?.filter(usrs=>usrs.privateArrayAccepted.includes(socket.id)) || [] ).map((users, index) => (
        <PrivateJspanel user={user} key={users.socketId} targetPrivate={users} userprops={user} />
    ));

    return (
        <>
            {renderedUsers}
        </>
    );
})



const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PrivateTopLevel);