import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
import { Form, Input, Button, message, Space, Typography, Col, Row } from "antd";
import { auth } from '../../endpoints/login'
import Player from './elements/player/Player';
import PlayerApi from '../../pages/components/PlayerApi';

const { Title } = Typography;
var platform = require('platform');
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Login = ({ app, testStore, designPage, user, ...props }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [messages, setMessage] = useState('aucun');

    const success = (messagev) => {
        messageApi.open({
            type: 'success',
            content: messagev,
            style: {
                marginTop: '10vh',
            }
        });
    };

    const warning = (messagev) => {
        messageApi.open({
            type: 'danger',
            content: messagev,
            style: {
                color: 'red',
                marginTop: '10vh',
            }
        });
    };

    const onFinish = async (values) => {
        values.room = props.room
        values.platform = platform;
        values.fpHash = user?.fpHash
        setLoading(true);

        auth(values).then(resp => {

            if (resp.msg) {
                //   console.log(resp.token)
                success(resp.detail)
          
                    setLoading(false);
                    console.log("Connexion réussie ! Veuillez choisir un salon, s'il vous plaît.");
                    props?.dispatch(setUserApp({ InitUserState: 'setRoom', username: values.username, token: resp.token, room: props.room }))
          
            }
            else {
                warning(resp.detail)
                setLoading(false);
            }
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    const setRegister = () => {
        props?.dispatch(setUserApp({ InitUserState: 'register' }))
    }

    const setRecover = () => {
        props?.dispatch(setUserApp({ InitUserState: 'recover' }))
    }

    const setRoom = () => {
        props?.dispatch(setUserApp({ InitUserState: 'setRoom' }))
    }
    return (
        <>
            <Row style={{ backgroundColor: '#010103' }}>
           
                <Col span={24} style={{height:'80px'}}>  <PlayerApi room={props.room} /></Col>
                <Col span={24}> <Title level={3} style={{margin:0, textAlign: 'center', color: ' rgb(248 248 248)' }}>MAX VIDEOCHAT RADIO</Title></Col>
            </Row>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: 'center'
                }}
            >
                <div style={{ margin: '20px', padding: '50px', border: ' 0.5px solid', borderRadius: '16px', backgroundColor: 'black' }}>
                    <div >
                        <Form
                            name="loginForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{ span: 12 }}
                        >
                            <Form.Item
                                label={<span style={{ color: 'white' }}>Nom d'utilisateur</span>}
                                labelCol={{ span: 12 }}
                                labelAlign="left"
                                name="username"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre nom d'utilisateur!" }
                                ]}
                                initialValue={user?.username && user?.username}
                            >
                                <Input autoComplete="current-password" />
                            </Form.Item>

                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Mot de passe</span>}
                                labelCol={{ span: 12 }}
                                name="password"
                                rules={[
                                    { required: true, message: "Veuillez saisir votre mot de passe!" }
                                ]}
                            >
                                <Input.Password autoComplete="current-password" />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                                <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                                    Connexion
                                </Button>
                            </Form.Item>
                        </Form>
                        <Col span={24} style={{ justifyContent: 'center', fontSize: '13px' }}>
                            <span onClick={setRegister} style={{ color: 'white', fontSize: '13px' }}>Nouveau dans ce salon ? <div style={{ color: 'orange' }}>S'enregistrer</div></span>
                        </Col>

                        <Col span={24} style={{ justifyContent: 'center', fontSize: '13px' }}>
                            <span onClick={setRecover} style={{ color: 'white', fontSize: '13px' }}>Mot de passe oublié ? <div style={{ color: 'orange' }}>Récuperer</div></span>
                        </Col>
                        {contextHolder}
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(Login);
