import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { UserOutlined } from '@ant-design/icons';
import { } from '@chatscope/chat-ui-kit-react';
import { Col, Row, Typography, Radio, Button, Modal, Avatar, Divider } from 'antd';
import { SocketContext } from '../../SocketContext';
import { playAudioGift } from '../../chatUtils/SoundSysteme'
import { ShowAllGiftEndpoint } from '../../../../endpoints/giftEndpoint'
import * as InterfaceFunc from "../../../utils/ChatFunction"

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ShowAllGift = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [allGift, setAllGift] = useState([]);
    const [isModalOpenGift, setIsModalOpenGift] = useState(true);


    const checkPermit = ({ action, ...options }) => {
        props?.dispatch(setUserApp({ messageFromServeur: null }));
        if (Object.entries(options).length) {
            InterfaceFunc[action](socket, user, options.options, options.options2)
                .then(
                    function (response) {
                        props?.dispatch(setUserApp({ messageFromServeur: response }));
                    },
                    function (error) { props?.dispatch(setUserApp({ messageFromServeur: error })); }
                );
        }
    }

    const showModalAllGift = () => {
        setIsModalOpenGift(true);
    };
    const handleOkGift = () => {
        setIsModalOpenGift(false);
        props.setShowModalAllGift(false)
    };
    const handleCancelGift = () => {
        setIsModalOpenGift(false);
        props.setShowModalAllGift(false)
    };

    useEffect(() => {
        if (isModalOpenGift) {
            ShowAllGiftEndpoint(user?.token, user?.room, user?.socketId).then(data => {
                setAllGift(data.gift)
            });
        }
    }, []);


    return (
        <>
            <Modal style={{ textAlign: 'center' }} title="Offrir un cadeau" open={isModalOpenGift} onOk={handleOkGift} onCancel={handleCancelGift}>
                <Row>
                    {
                        allGift.map((obj, key) => (
                            <Col span={4} >

                                <Row>
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Button onClick={() => checkPermit({ action: 'sendGif', options: props.userinfos.socketId, options2: obj })} style={{ height: '56px' }}>
                                            <Avatar style={{ borderColor: 'grey', backgroundColor: 'white' }} shape="square" size={48} src={`${URL}/systeme/image/gift/giftNoAnimated/${obj.image}`} />
                                        </Button>
                                        <Col span={24}>{obj.name}</Col>
                                        <Col span={24}><img src={'/gold.png'} /> {obj.price} </Col>
                                    </Col>
                                </Row>

                            </Col>
                        ))
                    }
                    <Divider />
                 
                   <div> {ref.templateTexteColorState.token_virtual}</div>  jetons restant
                </Row>
            </Modal>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ShowAllGift);
