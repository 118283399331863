import { axios } from './axios_custom';

export const adminGetAllUserApi = (token, room, socketId) => {
    return axios.get(`/adminGetAllUser`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllShoutcastApi = (token, room, socketId) => {
    return axios.get(`/adminGetAllShoutcast`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de la liste de Shoutcast :", error);
            throw error;
        });
}

export const adminGetAllPunchImageApi = (token, room, socketId) => {
    return axios.get(`/adminGetAllPunchImageApi`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllRoomApi = (token, room, socketId) => {
    return axios.get(`/adminGetAllRoom`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

export const adminGetAllRoleApi = (token, room, socketId) => {
    return axios.get(`/adminGetAllRole`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const adminGetAllTemplate = (token, room, socketId) => {
    return axios.get(`/adminGetAllTemplate`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}

export const adminGetAllTemplateColor = (token, room, socketId) => {
    return axios.get(`/adminGetAllTemplateColor`, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi des roles :", error);
            throw error;
        });
}




export const adminDeleteShoutcastApi = (token, room, socketId , target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        room,
        socketid: socketId,
        target : target
    };

    return axios
        .post(`/adminDeleteShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const adminPunchDeleteImageApi = (token, room, socketId , target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        room,
        socketid: socketId,
        target : target
    };

    return axios
        .post(`/adminPunchDeleteImageApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const updateRole = (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/updateRole`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminUpdateShoutcastApi = ( token, room, socketId , newip , dbId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        dbId,
        newip,
        room,
        socketId
    };

    return axios
        .post(`/adminUpdateShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const updateUser = (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/updateUser`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const updateRoom = (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/updateRoom`, data, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}


export const adminAddUserApi = (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/adminAddUserApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddShoutcastApi = (data, token, room, username ,socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        username,
        data,
        room,
        socketid: socketId
    };

    return axios
        .post(`/adminAddShoutcastApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const adminAddRoomApi = (data, token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    
    // Ajoutez les données du formulaire à l'objet FormData
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            formData.append(key, data[key]);
        }
    }

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminAddRoomApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminAddPunchImageApi = (data, token, room, socketId, formData,username) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    
    // Ajoutez les données du formulaire à l'objet FormData
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            formData.append(key, data[key]);
        }
    }

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);

    return axios
        .post(`/adminAddPunchImageApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminPunchSetActiveImageApi = ( token, room, socketId,username ,target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
 
    let formData = new FormData();
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);
    formData.append("target", target);

    return axios
        .post(`/adminPunchSetActiveImageApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            alert('Une erreur (454323) s\'est produite');
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSetActiveShoutcastApi = ( token, room, socketId,username ,target) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
 
    let formData = new FormData();
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);
    formData.append("username", username);
    formData.append("target", target);

    return axios
        .post(`/adminSetActiveShoutcastApi`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            alert('Une erreur (454323) s\'est produite');
            console.error("Une erreur s'est produite lors de la requête adminSetActiveShoutcastApi :", error);
            throw error;
        });
}




export const adminSendImageTemplate = ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendImageTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSendLogoTemplate = ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendLogoTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

export const adminSendColorTemplate = ( token, room, socketId, formData) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data', // N'oubliez pas d'ajouter le bon en-tête
    };

    //data non contenu dans le form mais neccessaire
    formData.append("token", token);
    formData.append("room", room);
    formData.append("socketId", socketId);

    return axios
        .post(`/adminSendColorTemplate`, formData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}



export const adminAddRoleApi = (data, token, room, socketId) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const requestData = {
        data,
        room,
        socketid: socketId,
       
    };

    return axios
        .post(`/adminAddRoleApi`, requestData, { headers })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête :", error);
            throw error;
        });
}

