import { useContext, useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { AndroidOutlined, AppleOutlined, SettingOutlined } from '@ant-design/icons';
import { ConversationList, TypingIndicator, MessageInput, ChatContainer, MessageList, Message, MessageSeparator, Avatar, Conversation, ConversationHeader } from '@chatscope/chat-ui-kit-react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Radio, Tabs, Badge, Row, Col, Modal, Button, Input, Image } from 'antd';
import ModalShowGiphyBig from './ModalShowGiphyBig'
import { SocketContext } from '../../SocketContext';


const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalEditMessage = ({ user, ...props }) => {
    const socket = useContext(SocketContext);
    const [updateMessage , setUpdateMessage]= useState()

    const editMessageTexte = () => {
      
        const messageUpdated = {
            ...props.messageToEdit?.current,
            message: updateMessage,
          };

        socket.emit("updateMessage", messageUpdated)
    }

    const deleteMessage = () => {
        socket.emit("deleteMessage", props.messageToEdit?.current)
    }
/*
    const kickUser = (kickUserz) =>{
        socket.emit("kickUser", value)
    }
    */

    return (
        <>
            <Modal
                title="Modifier/supprimer le message"
                open={props.editMessage}
                onCancel={props.closeModal} 
                footer={null}
            >
                <Row >
                    <Col span={18} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Envoyer par:<bold style={{ fontWeight: 'bold', textTransform: 'uppercase' }}> {props.messageToEdit?.current?.from}</bold> </span>
                        <span>server time: {props.messageToEdit?.current?.time} </span>
                        <span>socketId: {props.messageToEdit?.current?.socketId} </span>

                        <Input defaultValue={props.messageToEdit?.current?.message} onChange={(e) => setUpdateMessage(e.target.value)} />
                        <span><Button primary onClick={editMessageTexte}>editer</Button> 
                        <Button primary onClick={deleteMessage}>Supprimer</Button>
                        </span>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModalEditMessage);
