import { useContext, useState, useEffect, useRef, Fragment, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { AndroidOutlined, AppleOutlined, ConsoleSqlOutlined, SettingOutlined } from '@ant-design/icons';
import { ConversationList, TypingIndicator, MessageInput, ChatContainer, MessageList, Message, MessageSeparator, Avatar, Conversation, ConversationHeader, Sidebar } from '@chatscope/chat-ui-kit-react';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Radio, Tabs, Badge, Row, Col, Modal, Button, Input, Image } from 'antd';
import { SocketContext } from '../../SocketContext';
import ModalEditMessage from './ModalEditMessage'
import ModalRoomSelect from '../rightSide/ModalRoomSelect'
import InformRoomUserEntrance from './InformRoomUserEntrance';
import MessageFromUser from './MessageFromUser';
import MessageFromSysteme from './MessageFromSysteme';
import { LinearGradient, RadialGradient } from 'react-text-gradients'
import { EventEmitter } from '../../../utility/Emitter'


const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Onglet = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);
    const [message, setMessage] = useState([]);
    const [mode, setMode] = useState('top');
    const [tab, setTab] = useState();
    const [activeTab, setActiveTab] = useState();
    const [lastMessage, setLastMessage] = useState(null);
    const [showBadge, setShowBadge] = useState(true);
    const [messageServeur, setMessageServeur] = useState(null)
    const [isModalOpenserveur, setIsModalOpenServeur] = useState(false);
    const [antModalRooms, setAntModalRooms] = useState(false);
    const [firstTabKey, setFirstTabKey] = useState(null);

    const [editMessagePackage, setEditMessagePackage] = useState()

    useEffect(() => {
        props?.dispatch(setUserApp({ tab: tab }));
        if (tab?.length > 0) {
            setFirstTabKey(String(tab[0].key));
        }
    }, [tab]);

    useEffect(() => {
        //apres que tab change et seter le firstTabKey on force le click sur cette tab
        if (tab?.length > 0) {
            handleTabClick(firstTabKey)
        }
    }, [firstTabKey]);

    useEffect(() => {
        if (messageServeur) {
            setIsModalOpenServeur(true)
        }
    }, [messageServeur]);


    useEffect(() => {
        if (socket) {
            socket.on('messageHistory', handleMessageHistory);
            socket.on('message', handleMessage);
            socket.on('updateMessage', (data) => updateMessage(data));
            socket.on('deleteMessage', (data) => deleteMessage(data));
            socket.on('addTab', addTab);
            socket.on('updateMessageDetailConvo', updateMessageDetailConvo);
            socket.on('disconnectUser', (id, roomData) => closeTabAndMessageUser(id, roomData));
        }

        const closeTabAndMessageUser = (id, roomData) => {
            onEdit(id, 'remove')
            props?.dispatch(setUserApp({ messageFromServeur: `vous avez été kicker de la room ${roomData.title}` }));
        }

        return () => {
            if (socket) {
                socket.off('messageHistory', handleMessageHistory);
                socket.off('message', handleMessage);
                socket.off('updateMessage', updateMessage);
                socket.off('deleteMessage', (data) => deleteMessage(data));
                socket.off('addTab', addTab);
                socket.off('updateMessageDetailConvo', updateMessageDetailConvo);
                socket.off('disconnectUser', (id, roomData) => closeTabAndMessageUser(id, roomData));
            }
        };
    }, [socket]);

    useEffect(() => {
        //au premier chargement on parcours les room et affiche dans le tab celui selectionner
        user?.roomInfos.filter(room => room.id === user?.selectedRoom)
            .map((room, index) => (
                setTab([room])
            ))
        const emitter = EventEmitter().subscriber('informRoomUserEntrance', data => {
            infRoomUserEntrance(data.user, data.message, data.state, data.idRoom)
        })
        return () => {
            emitter.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (lastMessage && user?.selectedRoom != lastMessage?.roomId) {
            setTab(prevTab => prevTab.map(value => {
                if (value.id === lastMessage?.roomId) {
                    return {
                        ...value,
                        unReadMessage: value.unReadMessage + 1,
                    };
                }
                return value;
            }));
        }
        scrollToBottom()
    }, [lastMessage]);

    useEffect(() => {
        if (editMessagePackage) {
            setMessage((messages) =>
                messages.map((value) => {
                    if (value.messageId === editMessagePackage.messageId) {
                        return {
                            ...value,
                            message: editMessagePackage.message
                        };
                    } else {
                        return value;
                    }
                })
            );
        }

    }, [editMessagePackage]);

    useEffect(() => {
        if (ref.subscription_level.current === 'free') {
            setTimeout(() => {
                messageBuyChat()
            }, 5000);
        }
    }, [ref.subscription_level.current])

    const messageBuyChat = () => {
        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
        let message =
        {
            status: 'dnd',
            time: formattedDate,
            message: `dasdasdasdasdasdadasd`,
            from: 'Systeme',
            for: null,
            type: 'messageBuyChat',
            room: '1001',
            roomId: 1,
            typeTab: 'room',
            avatar: 'uploads/3135158fcab96cedf38ba7832da314be.jpg',
            formatMessage: {
                bold: false,
                italic: false,
                underline: false,
                colorText: '#00ff1e'
            },
        }

        setMessage((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            scrollToBottom()
        }, 1000);
    }

    const infRoomUserEntrance = (user, messageText, state, idRoom) => {

        let currentDate = new Date();
        let formattedDate = currentDate.toISOString();
        let message =
        {
            status: 'dnd',
            time: formattedDate,
            message: `${user.username} ${messageText}`,
            from: 'Systeme',
            for: null,
            type: 'messageSysteme',
            room: '1001',
            roomId: idRoom,
            typeTab: 'room',
            avatar: 'uploads/3135158fcab96cedf38ba7832da314be.jpg',
            formatMessage: {
                bold: false,
                italic: false,
                underline: false,
                colorText: '#00ff1e'
            },
            roleColor: 'red',
            messageId: formattedDate,
            socketId: 'messageSysteme',
            state: state,
            messageColorSysteme: state === 'in' ? 'green' : 'red'
        }

        setMessage((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            scrollToBottom()
        }, 1000);
    }

    const updateMessage = (messageToUpdate) => {
        setEditMessagePackage(messageToUpdate)
    };

    const deleteMessage = (message) => {
        setMessage((messages) =>
            messages.filter((value) => value.messageId !== message.messageId)
        );
    }

    const handleMessage = (message) => {

        const urlRegex = /^(?!https:\/\/media)(ftp|http|https):\/\/[^ "]+/g;
        const matches = message.message.match(urlRegex);
        // si le message n'est pas une image mais une adresse URL
        if (matches && message.type !== 'image') {
            let modifiedMessage = message.message;

            matches.forEach((url) => {
                const clickableLink = `<a href="${url}" target="_blank">${url}</a>`;
                modifiedMessage = modifiedMessage.replace(url, clickableLink);
            });

            // Utilisez dangerouslySetInnerHTML pour interpréter le HTML
            message.message = <div dangerouslySetInnerHTML={{ __html: modifiedMessage }} />;
        }

        setLastMessage(message)
        setMessage((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
    }

    const handleMessageHistory = (messageHistory) => {
        setMessage((prevMessages) => [...prevMessages, ...messageHistory]);
        setTimeout(() => {
            scrollToBottom();
        }, 1000);

    };

    const scrollToBottom = () => {
        const elementscrollTop = ref['scrollContainerRef']?.current;

        if (elementscrollTop) {
            const scrollHeight = elementscrollTop.scrollHeight;
            const clientHeight = elementscrollTop.clientHeight;
            const maxScrollTop = scrollHeight - clientHeight;
            elementscrollTop.scrollTop = maxScrollTop;
        }
    };

    const addTab = (i) => {
        setTab((oldTabs) => [...oldTabs, i]);
        handleTabClick(i.key)
    }

    const updateMessageDetailConvo = (userToUpdate) => {
        setMessage((messages) =>
            messages.map((value) => {
                if (value.socketId === userToUpdate.socketId) {
                    return {
                        ...value,
                        status: userToUpdate.status,
                        avatar: userToUpdate.avatar
                    };
                } else {
                    return value;
                }
            })
        );
    };

    const handleTabClick = (targetKey) => {
        props?.dispatch(setUserApp({ selectedRoom: targetKey }));
        setActiveTab(String(targetKey));

        const clickedTab = tab.find((item) => item.key == targetKey);
        if (clickedTab) {
            const title = clickedTab.title;
            if (clickedTab.type === 'user') {
                props?.dispatch(setUserApp({ typeTab: 'user' }));
            }
            else {
                props?.dispatch(setUserApp({ typeTab: 'room' }));
            }
        }

        setTab(prevTab => prevTab.map(value => {
            if (value.id == targetKey) {
                return {
                    ...value,
                    unReadMessage: 0, // Mettez à jour les propriétés de l'objet souhaitées
                };
            }
            return value;
        }));
    };

    const onEdit = (targetKey, action) => {
        props?.dispatch(setUserApp({ messageFromServeur: null }));

        if (action === 'add') {
            setAntModalRooms(true)
        } else {
            const typeTab = tab.find(item => item.id == targetKey);
            if (typeTab?.typeTab === 'room') {
                remove(Number(targetKey));
                socket.emit('userCloseTab', targetKey, typeTab)
            }
            if (!typeTab?.typeTab) {
                remove(targetKey);
            }
            if (firstTabKey) {
                handleTabClick(firstTabKey)
                setActiveTab(String(firstTabKey))
            }
            else {
                handleTabClick(targetKey)
            }
        }
    };

    const remove = (targetKey) => {
        setTab((prevTab) => prevTab.filter(item => item.id !== targetKey));
    }

    useEffect(() => {

    }, [user.selectedRoom])

    return (
        <>
            <div style={{ backgroundColor: 'black' }}>
                <Tabs
                    onChange={(activeKey) => handleTabClick(activeKey)}
                    onEdit={((activeKey, action) => onEdit(activeKey, action))}
                    tabPosition={mode}
                    type="editable-card"
                    activeKey={activeTab}
                >
                    {tab && tab.map((item, index) => (

                        <Tabs.TabPane
                            key={item.key}
                            tab={<span>{item.title} <Badge count={showBadge ? item.unReadMessage : 0} /> </span>}
                        >

                            <div className='mainConvo' style={{ overflowY: 'scroll', backgroundSize: '100% 100vh', backgroundRepeat: 'no-repeat' }} ref={ref.scrollContainerRef}   >
                                <div  >
                                    <ConversationList >
                                        {message.filter(value => Number(value.roomId) === Number(user.selectedRoom) && value?.typeTab == 'room' || value.roomId == user.selectedRoom)
                                            .map((value, index, array) =>

                                                <>
                                                    <MessageFromUser message={value} ref={ref} />
                                                </>
                                            )}
                                    </ConversationList>
                                </div>
                            </div>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
            <ModalRoomSelect antModalRooms={antModalRooms} setAntModalRooms={setAntModalRooms} />
        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Onglet);