import { useState } from 'react';
import { useRef } from 'react';

const useRefs = () => {

  const [templateTexteColorState, setTemplateTexteColorState] = useState({
    conversationMessage: 'white',
    conversationActive: 'white',
    //conversation: 'white',
    conversationMoment: 'green',
    conversationModifyTexte: 'red',
    isModalOpenPay : false,
    token_virtual: '1'
  })

  const [OpenModal, setOpenModal] = useState({
    isModalOpenPay : false
  })



  /*
  const [soundRoom , setSoundRoom] = useState({
    soundEntrance :null
  })
*/


  //template Colors
  const subscription_level = useRef()
  const entranceMp3 = useRef()
  const exitMp3 = useRef()
  const SoundEffect = useRef('true')
  const token_virtual = useRef(null)

  const UserColorText = useRef(null) 

  const ZoneinputMessage = useRef(null);
  const ZoneMiddleComponent = useRef(null)
  const ZoneToolsIcone = useRef(null);
  const scrollContainerRef = useRef(null);



  const logo = useRef(null);
  //utils
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const modalRef = useRef(null);
  const zoneUserList = useRef(null)
  const bottonBox = useRef(null)


  // reset ref
  //template color
  const resetRefs = () => {

    entranceMp3.current = null
    ZoneinputMessage.current = null;
    ZoneMiddleComponent.current = null;
    zoneUserList.current = null;
    ZoneToolsIcone.current = null;
    scrollContainerRef.current = null;
    bottonBox.current = null;
    logo.current = null;

    // utils
    inputRef.current = null;
    buttonRef.current = null;
    modalRef.current = null;

  };

  return {
    token_virtual,
    subscription_level,
    UserColorText,
    SoundEffect,
    entranceMp3,
    exitMp3,
    ZoneinputMessage,
    ZoneMiddleComponent,
    ZoneToolsIcone,
    scrollContainerRef,
    zoneUserList,
    bottonBox,
    logo,
   
    /*color template */
    templateTexteColorState,
    setTemplateTexteColorState,

    //ouvre les modal 
    OpenModal,
    setOpenModal,

    /* sound */
  //  soundRoom,
  //  setSoundRoom,

    inputRef,
    buttonRef,
    modalRef,

    // ... Ajoutez d'autres références au besoin ...
    resetRefs,
  };
};

export default useRefs;