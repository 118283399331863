import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef ,forwardRef } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp, setDesignPage } from '../../../../action/action';
import { Avatar } from '@chatscope/chat-ui-kit-react';
import { SocketContext } from '../../SocketContext';
import { Col, Row, Modal, Button } from 'antd';
import { VideoCameraOutlined, AudioOutlined } from '@ant-design/icons';
import * as InterfaceFunc from "../../../utils/ChatFunction"
import Dev from './ModeDevShowUserObject'
import ShowAllGift from '../Gift/ShowAllGift';


const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;


    const ModalUserlistAction = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [message, setMessage] = useState();
    const socket = useContext(SocketContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModalAllGift , setShowModalAllGift] = useState(false)

    const askQuestion = (array) => {
        InterfaceFunc.askQuestion(socket, array)
    }

    const handleOk = () => {
        setIsModalOpen(false);
        setMessage(null)
    };

    useEffect(() => {
        if (message) {

            setIsModalOpen(true);
        }
    }, [message]);

    const RespondModalAction = (value) => {
        return (
            <>
                <Modal title="Réponse du serveur" open={isModalOpen} onOk={handleOk} cancelButtonProps={{ style: { display: 'none' } }} >
                    <p>{message}</p>
                </Modal>
            </>
        )
    }

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 1
            }}
        />
    );

    function Micro(propss) {
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'true') {
            return <AudioOutlined style={{ color: 'white' }} ref={ref => props.iconeMicroRef[propss.user.socketId] = ref} onClick={() => propss.user.socketId !== user?.socketId && user?.addConsumer(null, propss.user.socketId, propss.user.producerTransports, 'audio', propss.user.username)} />
        }
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'false') {
            return <a href="#" onClick={() => propss.user.socketId !== user?.socketId && askQuestion({ action: 'listen', target: propss.user.socketId })}>ask</a>
        }
    }

    function Micro(propss) {
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'true') {
            return <AudioOutlined style={{ color: 'white' }} ref={ref => props.iconeMicroRef[propss.user.socketId] = ref} onClick={() => propss.user.socketId !== user?.socketId && user?.addConsumer(null, propss.user.socketId, propss.user.producerTransports, 'audio', propss.user.username)} />
        }
        if (propss.user.audioEnabled && propss.user.autoAccessMediaDevices === 'false') {
            return <a href="#" onClick={() => propss.user.socketId !== user?.socketId && askQuestion({ action: 'listen', target: propss.user.socketId })}>ask</a>
        }
    }

    const checkPermit = ({ action, ...options }) => {
        props?.dispatch(setUserApp({ messageFromServeur: null }));
        if (Object.entries(options).length) {
            InterfaceFunc[action](socket, user, options.options, options.options2)
                .then(
                    function (response) {
                        props?.dispatch(setUserApp({ messageFromServeur: response }));
                    },
                    function (error) { props?.dispatch(setUserApp({ messageFromServeur: error })); }
                );
        }
    }

    const VideoButton = (props) => {
        return (
            !props.userinfos.videoBlocked && user?.role.block_user_video === 1 ?
                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'blockVideo', options: props.userinfos.socketId })}>BlockVideo</Button>
                :
                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'unBlockVideo', options: props.userinfos.socketId })}>UnBlockVideo</Button>
        )
    }

    const AudioButton = () => {
        return (
            !props.userinfos.audioBlocked && user?.role.block_user_audio === 1 ?

                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'blockAudio', options: props.userinfos.socketId })}>BlockAudio</Button>
                :
                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'unBlockAudio', options: props.userinfos.socketId })}>UnBlockAudio</Button>
        )
    }

    const BlockMessage = () => {

        return (
            props.userinfos.blockUserMessage == '0' ?
                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'blockMessage', options: props.userinfos })}>BlockMessage</Button>
                :
                <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'unBlockMessage', options: props.userinfos })}>UnBlockMessage</Button>
        )
    }


    return (
        <>
        {showModalAllGift &&  <ShowAllGift setShowModalAllGift={setShowModalAllGift} userinfos={props.userinfos} ref={ref} /> }
       
            <div className="close-modal" ref={ref => props.userCloseModalRef[props.userinfos.socketId] = ref}>
                <dialog id="modal-dropdown-list-" ref={ref => props.userOpenModalRef[props.userinfos.socketId] = ref} className="modal" open="">
                    <div className="content-modal-list-users">
                        <div className="img-name-modal">
                            <div className="modalHeader">
                                <div>
                                    <Avatar
                                        style={{ height: '80px', width: '80px', left: '15px', position: 'absolute', top: '15px' }}
                                        alt="Remy Sharp"
                                        src={`${URL}/${props.userinfos.avatar}`}
                                    />
                                </div>
                                <div>
                                    <h1 style={{ fontSize: '25px', textAlign: 'center' }}>{props.userinfos.username}</h1>
                                </div>
                            </div>
                        </div>

                        <Row style={{ textAlign: 'left' }}>
                            <Col span={24} >
                                <span> socket ID : {props.userinfos.socketId}</span>
                            </Col>
                            <Col span={24} >
                                <span>role : {props.userinfos.role}</span>
                            </Col>
                            <Col span={24} >
                                <span>Age : {props.userinfos.age}</span>
                            </Col>

                            <Col span={24} >
                            
                                <Button style={{ width: '100%' }} onClick={()=>setShowModalAllGift(true)}>Offrir un cadeau</Button>
                            </Col>


                            <Col span={24} >
                                <Button style={{ width: '100%' }} disabled={props.userinfos.privateArrayAccepted.includes(user?.socketId) || user?.role?.send_private === '0'} onClick={() => checkPermit({ action: 'invitePrivate', options: props.userinfos.socketId, options2: user?.socketId })}>
                                    {props.userinfos.privateArrayAccepted.includes(user?.socketId) ? 'Conversatin privé en cours' : 'Invite Private'}
                                </Button>
                            </Col>
                            <Col span={24} >
                                {user?.role?.kick_user === 1 &&
                                    <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'kick', options: props.userinfos.socketId, options2: user?.selectedRoom })}>kick</Button>
                                }
                            </Col>
                            <Col span={24} >
                                {user?.role?.ban_user === 1 &&
                                    <Button style={{ width: '100%' }} onClick={() => checkPermit({ action: 'ban', options: props.userinfos, options2: user?.selectedRoom })}>Kick & Ban</Button>
                                }
                            </Col>
                            <Col span={24} >
                                {user?.role?.block_user_audio === 1 &&
                                    <AudioButton userinfos={props.userinfos} />
                                }
                            </Col>
                            <Col span={24} >
                                {user?.role?.block_user_video === 1 &&
                                    <VideoButton userinfos={props.userinfos} />
                                }
                            </Col>

                            <Col span={24} >
                                {user?.role?.block_message === 1 &&
                                    <BlockMessage userinfos={props.userinfos} />
                                }
                            </Col>
                            {/*}
                            <Col span={24} >
                                <Button style={{ width: '100%' }} onClick={() => askQuestion({ action: 'sendQuestion', options: props.userinfos })}>SendQuestion</Button>
                            </Col>

                           {*/}
                        </Row>
                        <Dev userinfos={props.userinfos} />
                    </div>
                </dialog>
            </div>
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalUserlistAction);