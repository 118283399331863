import { useContext, useState, useEffect, useRef, Fragment, forwardRef } from 'react';
import { connect } from 'react-redux';
import { Radio, Tabs, Badge, Row, Col, Modal, Button, Input, Image } from 'antd';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { ConversationList, Avatar, Conversation, Message } from '@chatscope/chat-ui-kit-react';

import TimeAgo from 'react-timeago';

import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(frenchStrings)

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;


const InformRoomUserEntrance = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    let currentDate = new Date();
    let formattedDate = currentDate.toISOString();
    const [currentTime, setCurrentTime] = useState(Date.now());

    const timeStyles = {
        color: ref.templateTexteColorState.conversationMoment,
        // Ajoutez d'autres styles au besoin
    };

    useEffect(() => {
        let animationFrameId;

        function animate(timestamp) {
            setCurrentTime(Date.now());
            animationFrameId = requestAnimationFrame(animate);
        }

        // Démarrer l'animation
        animationFrameId = requestAnimationFrame(animate);

        // Nettoyer le frame d'animation lors du démontage du composant
        return () => cancelAnimationFrame(animationFrameId);
    }, []); // Tableau de dépendances vide pour effectuer le montage et le démontage uniquement



    return (

        <Conversation
            style={{ color: 'orange', backgroundColor: ref.templateTexteColorState.conversationActive, cursor: 'auto' }}
            key={1}

            info={
                <div style={{
                    fontSize: '15px',
                }}>
                    <Message style={{ borderRadius: '3px' }} model={{

                    }}>
                        <Message.TextContent style={{ padding: '20px' }} text=<span style={{ borderRadius: '0px', margin: '1px', padding: '1px', color: props.message.messageColorSysteme, fontSize: '12px' }}>
                            {props.message.message}
                          
                            <TimeAgo style={timeStyles} date={props.message.time} formatter={formatter} live={true} locale="fr" />
                        </span> />
                    </Message>

                </div>
            }

        >

        </Conversation>



    )

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InformRoomUserEntrance);