import { useState, useContext, useEffect, useRef, Fragment, useMemo, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import InputEmoji from 'react-input-emoji'
import { Col, Row, ColorPicker, Button, Modal } from 'antd';
import Giphy from '../InputChat/GiphySearch'
import { SocketContext } from '../../SocketContext';
import { BoldOutlined, PictureOutlined, ItalicOutlined, UnderlineOutlined, FileGifOutlined } from '@ant-design/icons';
import { EventEmitter } from '../../../utility/Emitter';
import SendImageToChat from './SendImageToChat';

const InputChat = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

  const socket = useContext(SocketContext);
  const [text, setText] = useState('')
  const [modalVisibleGiphySearch, setModalVisibleGiphySearch] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [colorHex, setColorHex] = useState('#1677ff');
  const [formatHex, setFormatHex] = useState('hex');
  const [tipyng, setTipyng] = useState(false)
  const [delaisTyping, setDelaisTyping] = useState(false)
  const [modalSendImageChat, setmodalSendImageChat] = useState(null)
  const buttonRefEmoji = useRef(null);

  const OpenMondalSendImage = () => {
    setmodalSendImageChat(true)
  }

  function handleOnEnter(text) {

    const regex = /(<([^>]+)>)/ig;
    const clean = text.replace(regex, '');
    var date = new Date().toLocaleString("en-US", { timeZone: "America/Montreal" });
    var dateObj = new Date(date);
    const isoDate = dateObj.toISOString();

    let room
    user?.typeTab === 'user' ? room = user?.selectedRoom : room = Number(user?.selectedRoom)

    let messagePackage = {
      status: user?.status,
      time: isoDate,
      message: clean,
      from: user?.username,
      for: null,
      type: 'text',
      room: user?.room,
      roomId: room,
      typeTab: user?.typeTab,
      avatar: user?.avatar,
      formatMessage: textUserStyle,
      roleColor: user?.roleColor
    }
    text.trim().length >= 1 && socket.emit("message", messagePackage);
    setImageUrl(null);
    setText('')
  }
  const hexString = useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()),
    [colorHex],
  );

  useEffect(() => {
    if (ref.UserColorText.current) {
      /*set input color text message  */
      setColorHex(ref.UserColorText.current)
    }
  }, [ref.UserColorText.current])

  function debounceOnce(func, delay) {
    let timerId = null;
    return function (...args) {
      if (timerId === null) {
        func(...args);
        timerId = setTimeout(() => {
          timerId = null;
        }, delay);
      }
    };
  }

  // Exemple d'utilisation :
  const debouncedFunction = debounceOnce(() => {
    if (socket) {
      socket.emit("typing", text);
    }
  }, 10000); // 5000 millisecondes (5 secondes)

  let isDebounced = useRef(false);

  useEffect(() => {
    if (/^https?:\/\/.*\.(bmp|gif|jpe?g|png|svg|tiff?|webp)$/i.test(text)) {
     
      setImageUrl(text);
      handleOnEnter(text);
      setText('');
    } else if (!isDebounced.current && text) {
      isDebounced.current = true;
      socket.emit("typing", text, user?.selectedRoom);
      setTimeout(() => {
        isDebounced.current = false;
      }, 4000);
    }
  }, [text]);

  useEffect(() => {
    if (socket) {
      socket.on('setInputOff', setInputOff);
      socket.on('setInputOn', setInputOn);
    }
    //ecouter une evenement socket io appeller une fonction de rappelle 
  }, [socket]);

  const setInputOff = () => {
    setText('')
    setMax('0')
  }

  const setInputOn = () => {
    setMax(max)
  }

  const bold = useRef(false)
  const italic = useRef(false)
  const underline = useRef(false)
  const [textUserStyle, setTextUserStyle] = useState({ bold: bold.current, italic: italic.current, underline: underline.current, colorText: colorHex })
  const [max, setMax] = useState(600)
  const [forceUpdateState, setForceUpdateState] = useState(1)
  const setTextUserStyleHandler = (value) => {

    switch (value) {
      case 'bold':
        bold.current = !bold.current
        break;
      case 'italic':
        italic.current = !italic.current
        break;
      case 'underline':
        underline.current = !underline.current
        break;
    }
    setTextUserStyle({ bold: bold.current, italic: italic.current, underline: underline.current, colorText: hexString })

  }

  useEffect(() => {
    const myElement = document.getElementsByClassName('react-input-emoji--input')[0];
    myElement.style.color = hexString;
    setTextUserStyle({ bold: bold.current, italic: italic.current, underline: underline.current, colorText: hexString })
  }, [colorHex])

  const handleEmojiClick = (emoji) => {
    setText((prevText) => prevText + emoji);
  };

  const handleButtonClick = () => {
    // Utilisez buttonRef.current pour accéder au bouton
    if (buttonRefEmoji.current) {
      // Déclenchez le clic sur le bouton
      buttonRefEmoji.current.click();
    }
  };



  return (
    <Row >
      <Col span={24} style={{ display: 'flex', paddingLeft: '10px' }}>
        <Col ref={ref.ZoneToolsIcone} className='niceBox'>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('bold')}><BoldOutlined style={{ color: !bold.current ? 'black' : 'green' }} /></Button>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('italic')}><ItalicOutlined style={{ fontSize: '15px', color: !italic.current ? 'black' : 'green' }} /></Button>
          <Button size={'small'} onClick={() => setTextUserStyleHandler('underline')}><UnderlineOutlined style={{ fontSize: '15px', color: underline.current ? 'green' : 'black' }} /></Button>
          <Button size={'small'} onClick={() => setModalVisibleGiphySearch(true)}><FileGifOutlined style={{ fontSize: '15px' }} /></Button>

          <ColorPicker
            size={'small'}
            style={{ top: '3px', position: 'relative' }}
            format={formatHex}
            value={colorHex}
            onChange={setColorHex}
            onFormatChange={setFormatHex}
          />

          <Button size={'small'} onClick={() => OpenMondalSendImage()}><PictureOutlined style={{ color: !bold.current ? 'black' : 'green' }} /></Button>

          <Button size={'small'} style={{}} onClick={handleButtonClick}  ><span ref={buttonRefEmoji}>😊</span></Button>


          {/*hexString*/}
          {/* addiche composant Giphy  */}
          <Giphy setText={setText} modalVisibleGiphySearch={modalVisibleGiphySearch} setModalVisibleGiphySearch={setModalVisibleGiphySearch} />
        </Col>
      </Col>
      <Col span={21} >
        <InputEmoji
          value={text}
          autoComplete="off"
          onChange={setText}
          cleanOnEnter
          onEnter={handleOnEnter}
          placeholder={max == '0' ? 'Vous ne pouvez pas écrire de message' : 'Écrire un message'}
          maxLength={max}
          buttonRef={buttonRefEmoji}
         
        />
      </Col>
      <Col span={3} ><Button onClick={()=>handleOnEnter(text)} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0, borderTopRightRadius:'25px', borderBottomRightRadius:'25px', height:'42px', marginTop:'5px'}}>Envoyer</Button></Col>

      {/* component send image to chat */}
      <SendImageToChat modalSendImageChat={modalSendImageChat} setmodalSendImageChat={setmodalSendImageChat} />
    </Row>
  )
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InputChat);