import { axios } from "./axios_custom"

export const registerUser = (data) => {
    return axios.post(`/registerUser`, data)
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'enregistrement :", error);
            throw error;
        });
}



