import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, AutoComplete, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllUserApi } from '../../../../endpoints/admin'

const { Column, ColumnGroup } = Table;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;


const ListeUser = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {

    const [dataa, setDataa] = useState(null)
    const [originalData, setOriginalData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredUsers = originalData.filter((user) =>
            user.username.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataa(filteredUsers);
        setSearchTerm(searchValue);
    };

    useEffect(() => {
        adminGetAllUserApi(user?.token, user?.room, user?.socketId).then(resp => {
            setDataa(resp.user)
            setOriginalData(resp.user)
        })
    }, [])

    return (
        <>
            <Row>
                <Col span={24} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="text"
                        placeholder="Rechercher une room"
                        value={searchTerm}
                        style={{ width: '100%' }}
                        onChange={handleSearch}
                    />
                    <Button size="large" onClick={() => props.setCurrentComponentObj({ name: 'addUser' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary" >+Ajouter</Button>
                </Col>
            </Row>

            <Table dataSource={dataa}>
                <Column
                    title="Avatar"
                    dataIndex="avatar"
                    key="avatar"
                    render={(avatar) => (
                        <Avatar size={32} src={`${URL}/uploads/${avatar}`} />
                    )}
                />

                <Column title="Username" dataIndex="username" key="username" />
                <Column title="Ip" dataIndex="ip" key="ip" />

                <Column
                    title="Role"
                    dataIndex="role"
                    key="role"
                />

                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <Button onClick={() => props.setCurrentComponentObj({ name: 'editUser', object: record })} type="primary" primary>Modifier</Button>
                            <Button onClick={() => props.setCurrentComponentObj({ name: 'deleteUser', object: record })} type="primary" danger>Supprimer</Button>
                        </Space>
                    )}
                />
                {/*}
            <Column
                title=<Button onClick={() => props.setCurrentComponentObj({ name: 'addUser'})} style={{ position: 'absolute', left: '-100px', bottom: '10px' }} type="primary" >Ajouter</Button>
                key="add"
            />
                {*/}
            </Table>
        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(ListeUser);
