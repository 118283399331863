import { axios } from './axios_custom';

export const avatar = (token, room, socketId, formData) => {
    return axios.post(`/avatar`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId
        }
    })
        .then(({ data }) =>data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

