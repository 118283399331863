import React, { useState } from 'react';
import { Modal, Button , Image } from 'antd';
import { connect } from 'react-redux';

const ModalShowGiphyBig = ({ user, ...props }) => {

    const showModal = () => {
        props.setShowModalShowGiphyBig(true);
    };

    const handleOk = () => {
        props.setShowModalShowGiphyBig(false);
    };

    const handleCancel = () => {
        props.setShowModalShowGiphyBig(false);
    };

    return (
        <>
            <Modal
                title={`Envoyer par : ${props.image.from}`}
                open={props.showModalShowGiphyBig}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <img src={props.image.message} alt="Girl in a jacket" style={{ width: '300px', height: 'auto', textAlign:'center' }} />
                </div>
            </Modal>
        </>
    );

}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(ModalShowGiphyBig);
