import { jsPanel } from 'jspanel4/es6module/jspanel';
import 'jspanel4/es6module/extensions/modal/jspanel.modal';
import 'jspanel4/dist/jspanel.min.css';
import { useState, useEffect, useRef, Fragment } from 'react';

const Jspanelwebcam = ({ ...props }) => {

    let id = null
    let audioAnalysers = {}
    let audioContext = useRef(null)
    let audioFftArray = null

    function initializeAudioTools() {
        
        let audioContextType = (window.AudioContext || window.webkitAudioContext);
        if (audioContextType) {
            audioContext.current = new audioContextType();
            audioFftArray = new Float32Array(256);
            if (audioContext.current) {
          
                setInterval(watchAudioTracks, 100);
            }
        }
    }

    function createAudioAnalyser(id, stream) {
      
        if (audioContext.current) {
         
            let source = audioContext.current.createMediaStreamSource(stream);
            let analyser = audioContext.current.createAnalyser();
            analyser.smoothingTimeConstant = 0.1;
            analyser.fftSize = 512;

            source.connect(analyser);
            //analyser.connect(audioContext.destination); // Makes the context play sound

            audioAnalysers[id] = analyser;
            // console.log('Analyser created id=', id);
        }
    }

    function watchAudioTracks() {
        for (id in audioAnalysers) {
            let analyser = audioAnalysers[id];
            analyser.getFloatFrequencyData(audioFftArray);

            let dBs = -Infinity;
            for (let i = 4, ii = audioFftArray.length; i < ii; i++) {
                if (audioFftArray[i] > dBs && audioFftArray[i] < 0) {
                    dBs = audioFftArray[i];
                }
            };

            /*
            Original formula to convert from dBs to linear scale (0..1):
            Math.pow(10, dBs / 20);
            Runo's version (exaggerated and normalized to ten levels):
            Math.round(Math.pow(10, dBs / 85) * 10) * 10; //Multiples of 10
            Math.ceil(Math.round(Math.pow(10, dBs / 85) * 100) / 5) * 5; //Multiples of 5
            */

            let volume = Math.round(Math.pow(10, dBs / 85) * 10) * 10;
            volume = (volume <= 10 ? 0 : volume); // Truncate 10% to 0%

            //$(`#meter_${id}`).css('height', `${volume}%`);

            var i = document.getElementById(`meter_${id}`)
            if (i) {
                i.style.height = volume + '%';
            }
        }
    }

    const handleClick = (e) => {
        let cl = e.target.closest('span').classList;
        props.removeJspanel(props.cam.id, props.cam.track.kind);
        e.target.removeEventListener('click', handleClick);
    };

    useEffect(() => {
        async function init() {
            let element = null;
            let stream = null
            element = document.getElementById(`jsPanel_${props.cam.id}`)
           
            if (!element) {
                let panel = await jsPanel.create({
                    headerControls: {
                        add: {
                            html: '<span class="fad fa-bars">x</span>',
                            name: 'menu',
                            position: 6,
                            handler: (panel, control) => {
                                if (props.cam.id === 'local') {
                                    if(props.webcam.current){
                                        props.toggleWebcam()
                                    }
                                    if(props.micro.current){
                                            props.toggleAudio()
                                    }
                                   
                                    props.removeJspanel(props.cam.id, props.cam.track.kind)
                                }
                                else {
                                    props.removeJSpanel(props.cam.id, props.cam.track.kind)
                                }
                            }
                        },
                        smallify: 'remove',
                        // menu: 'disable' // you could disable or hide the new control right here
                    },

                    id: () => `jsPanel_${props.cam.id}`,
                    resizeit: {
                        aspectRatio: 'content',
                        minHeight: 100
                    },
                    dragit: {
                        containment: [65, 20, 20]
                    },

                    theme: 'primary',
                    headerTitle: props.cam.username,
                    position: 'center-top 0 58',
                    panelSize: '170 160',
                    // panelSize: '170 130',
                    contentSize: '450 250',

                    content: `<div><video class='videoContainer' id=${props.cam.id} muted playsInline controls></video><div id="meter_${props.cam.id}"  class="audio-meter"></div>  </div>`,

                    callback: panel => {
                        // handler for the icons
                        for (const btn of panel.headertoolbar.querySelectorAll('span')) {
                            btn.addEventListener('click', handleClick);
                        }
                    }
                }).setControlStatus('close', 'remove');

            }
            else {
                console.log('existe deja ?')
            }

            element = document.getElementById(props.cam.id)

            if (element.srcObject) {
                stream = element.srcObject;
                element.srcObject.addTrack(props.cam.track);
            }
            else {
               
                stream = new MediaStream();
                stream.addTrack(props.cam.track);
                element.srcObject = stream;
            }

            console.log(props.cam.track.kind)

            if (props.cam.id !== 'local' && props.cam.track.kind === 'audio') {
                element.muted = false
                createAudioAnalyser(props.cam.id, stream);
            }

            var playPromise = element.play();

            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    // Automatic playback has already started!
                    // Show playing UI.
                })
                    .catch(error => {
                        alert('erreur')
                    });
            }
        }
        initializeAudioTools();
        init()

    }, [props])

    return (

        ''
    )

};

export default Jspanelwebcam;