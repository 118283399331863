import { axios } from "./axios_custom"

export const auth = (data) => {
    return axios.post(`/auth`, data)
        .then(({ data }) => data)
        .catch((error) => {
            if (error.response && error.response.status === 429) {
                alert( error.response.data);
            } else {
                console.error("Une erreur s'est produite lors de la requête d'authentification :", error);
            }
            throw error;
        });
}

