import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Switch, Input, Image } from 'antd';
import { AndroidOutlined, AppleOutlined, ConsoleSqlOutlined, SettingOutlined } from '@ant-design/icons';
import { ConversationList, TypingIndicator, MessageInput, Avatar, MessageList, Message, MessageSeparator, Conversation, ConversationHeader, Sidebar } from '@chatscope/chat-ui-kit-react';
import { connect } from 'react-redux';
import InformRoomUserEntrance from './InformRoomUserEntrance';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { LinearGradient, RadialGradient } from 'react-text-gradients'
import Pay from './Pay';
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const MessageFromUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [editMessage, SetEditMessage] = useState(false)
    const messageToEdit = useRef(null)
    const [forceState , setForceState] = useState(false)
    
    const OpenModaleditUserMessage = (obj) => {
        SetEditMessage(true)
        messageToEdit.current = obj
    }

    const test =() =>{
        ref.setOpenModal(prevState => ({
            ...prevState,
            isModalOpenPay: true, // ou false, selon ce que vous voulez définir
          }));
    }


    const MessageSysteme = () => {
        return (
            <>
                <div style={{fontSize:'20px', padding: '4px', borderRadius: '4px', backgroundColor: 'white' , width:'100%', textAlign:'center' }}>
            
                    <LinearGradient
                    onClick={test}
                        gradient={['to left', '#17acff ,#ff68f0']}
                        fallbackColor="black"
                    >
                        "Passer à la version Pro ! Cliquez ici pour débloquer toutes les fonctionnalités du tchat ."
                    </LinearGradient>
                </div>

            </>
        )
    }

    return (
        <div key={props.index + 1} style={{ display: 'flex', justifyContent: 'center' }} >
            <div key={props.index + 2} style={{ flex: '0 0 100%' }}>
              <MessageSysteme />
            </div>
     <Pay ref={ref} />
        </div>

    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageFromUser);