import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Table ,Columns } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../../action/action';
import { AppstoreOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;


const ListeUploadedFile = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const columns = [
        {
            title: 'Image',
            dataIndex: 'file_name',
            key: 'file_name',
            render: (file_name) => <img src={`${URL}/uploads/images/${file_name}`} alt="Image" style={{ width: '50px', height: '50px' }} />,

          },
        {
          title: 'Utilisateur',
          dataIndex: 'from_user',
          key: 'from_user',
        },
     
        {
          title: 'Action',
          dataIndex: 'file_name',
          key: 'file_name',
          render: (file_name) =><Button onClick={() => props.fileSelected(file_name)} style={{ backgroundColor: '#28a745', color: '#ffffff' }} type="default">
          Selectionner
        </Button>
        },
        {
            title: 'Action',
            dataIndex: 'file_name',
            key: 'file_name',
            render: (file_name) =><Button  style={{ backgroundColor: '#dc3545', color: '#ffffff' }} type="default">
            Supprimer
          </Button>
          },
      ];
      
    return (
        <Table
        dataSource={props.imageTemplateFromServer.map((item, index) => ({ ...item, key: index.toString() }))}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(ListeUploadedFile);
