const audioTools = {

    initializeAudioTools: function (audioContext, audioFftArray,id,audioAnalysers) {
        let audioContextType = (window.AudioContext || window.webkitAudioContext);
        if (audioContextType) {
            audioContext = new audioContextType();
            audioFftArray = new Float32Array(256);
            if (audioContext) {
                setInterval(() => this.watchAudioTracks(id,audioFftArray,audioAnalysers), 100);
            }
        }
    },

    watchAudioTracks: function (id,audioFftArray,audioAnalysers) {
        
        for (id in audioAnalysers) {
            
            let analyser = audioAnalysers[id];
            analyser.getFloatFrequencyData(audioFftArray);

            let dBs = -Infinity;
            for (let i = 4, ii = audioFftArray.length; i < ii; i++) {
                if (audioFftArray[i] > dBs && audioFftArray[i] < 0) {
                    dBs = audioFftArray[i];
                }
            };
            let volume = Math.round(Math.pow(10, dBs / 85) * 10) * 10;
            volume = (volume <= 10 ? 0 : volume); // Truncate 10% to 0%
        }
    },

    initializeDeviceOptions: function (audioConstraints, videoConstraints) {
        
        let supports = navigator.mediaDevices.getSupportedConstraints();

        audioConstraints = {
            sampleSize: 4,
            channelCount: 2
        };

        videoConstraints = {
            width: { max: 320 },
            height: { max: 180 },
            frameRate: { max: 10 }
        };

        if (!supports['sampleSize']) {
            delete audioConstraints['sampleSize'];
        }

        if (!supports['channelCount']) {
            delete audioConstraints['channelCount'];
        }

        if (Object.entries(audioConstraints).length === 0) {
            audioConstraints = true;
        }

        if (!supports['width']) {
            delete videoConstraints['width'];
        }

        if (!supports['height']) {
            delete videoConstraints['height'];
        }

        if (!supports['frameRate']) {
            delete videoConstraints['frameRate'];
        }

        if (Object.entries(videoConstraints).length === 0) {
            videoConstraints = true;
        }
        console.log(supports)
    }
};


module.exports = audioTools;
