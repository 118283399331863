import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table, Upload } from 'antd';
import { MenuUnfoldOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminAddRoomApi } from '../../../../endpoints/admin'
import Test from '../components/UploadFile'

import { compressImage } from '../../chatUtils/CompressImage'


const { Option } = Select;

const { Column, ColumnGroup } = Table;

const AddRoom = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {
  const [form] = Form.useForm();
  const [dataa, setDataa] = useState([])
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSelect = (value) => {
    setSearchValue(value);
    onSearch(value); // Appeler la fonction de recherche avec la valeur sélectionnée
  };

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    let file = null
    let formData = new FormData();

    if(values?.upload){
      file = await compressImage(values.upload[0].originFileObj)
     
      formData.append("upload", file);
    }
 
    adminAddRoomApi(values, user?.token, user?.room, user?.socketId, formData).then(resp => {
     
      form.resetFields();
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {

  }, [])

  return (

    <>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',

        }}

        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          className="custom-form-item"
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Please input your title!',
            },

          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: 'Please input your description!',
            },

          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          name="user_max"
          label="User maximum in room"
          rules={[
            {
              required: true,
              message: 'Please enter maximum!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value >= 1 && value <= 100) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('maximum must be between 1 and 100.'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          label="Password"
          name="password"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="upload"
          label="Room logo"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload name="logo"  listType="picture">
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="active"
          label="Active"

        >
          <Switch />
        </Form.Item>

        <Form.Item
          className="custom-form-item"
          labelCol={{ span: 8 }}
          wrapperCol={{ offset: 0, span: 16 }}
          name="show_history_on_connect"
          label="Show history on connect"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>
        <Test fileToSend={'test'} />
      </Form>

    </>
  );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);
