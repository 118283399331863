import { axios } from './axios_custom';

export const sendImageChat = (selectedRoom, token, room, socketId, formData) => {
  
    var date = new Date().toLocaleString("en-US", { timeZone: "America/Montreal" });
    var dateObj = new Date(date);
    const isoDate = dateObj.toISOString();

    return axios.post(`/sendImageChat`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            room: room,
            socketid: socketId,
            tabselected :selectedRoom,
            tabTypeUserOrRoom :'room',
            timeClient : isoDate
        }
    })
        .then(({ data }) => data)
        .catch((error) => {
            console.error("Une erreur s'est produite lors de la requête d'envoi de l'Avatar :", error);
            throw error;
        });
}

