import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { connect } from 'react-redux';
import { Badge, Divider } from 'antd';
import { actionMessage } from '../../../../reducer/reducer';
import { useEffect, useState, forwardRef, useRef } from 'react';

const TokenVirtual = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {


  const [token, setToken] = useState();
  const timeoutIdRef = useRef();

  useEffect(() => {
    const updateToken = () => {
      setToken((oldToken) => {
        const newToken = oldToken + user?.virtualTokenPerMinute;
        props?.dispatch(setUserApp({ token_virtual: newToken }))
        ref.setTemplateTexteColorState(prevState => ({
          ...prevState,
          ['token_virtual']: newToken
      }));
      
      
        
        return newToken;
      });
    };
  
    // Mettez en place l'intervalle
    const intervalId = setInterval(() => {
      updateToken();
    }, 5000);
  
    // Nettoyez l'intervalle lorsque le composant est démonté ou lorsque user.virtualTokenPerMinute change
    return () => clearInterval(intervalId);
  
  }, [user?.virtualTokenPerMinute]);


  useEffect(() => {
    setToken(ref?.token_virtual.current)
  }, [ref?.token_virtual.current]);


  return (
    <>

      <span>
        <img src="/gold.png" alt="credit" />
      </span>
      <span>
    
        <Badge overflowCount={999999} size={"small"} count={token} style={{ color: 'white', borderColor: 'transparent', backgroundColor: 'transparent' }} />
      </span>

    </>
  )
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TokenVirtual);
