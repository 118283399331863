import { Space, Layout } from 'antd';
import { connect } from 'react-redux';
import Nav from '../components/pages/layout/Nav';

const { Header, Footer, Content } = Layout;
const actualPage = 'contact';

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 64,
    paddingInline: 10,
    lineHeight: '64px',
    backgroundColor: '#7dbcea',
};

const contentStyle = {
    textAlign: 'center',
    minHeight: '76vh',
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#108ee9',
};

const footerStyle = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#7dbcea',

};

const Contact = () => {

    return (
        <Space direction="vertical" style={{ width: '100%', height: '100vh' }} size={[0, 48]}>
            <Layout>
                <Header style={headerStyle}>
                    <Nav pageSelected={actualPage} />
                </Header>
                <Content style={contentStyle}><h1>Contact</h1></Content>
                <Footer style={footerStyle}>Footer</Footer>
            </Layout>
        </Space>
    );
};

const mapStateToProps = ({ user, message }) => ({ user, message });
export default connect(mapStateToProps)(Contact);
