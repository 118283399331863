import { axios } from './axios_custom';

export const getActivePunch = (token, room) => {
    if(token){
        return axios.get(`/getActivePunch`, {
            headers: {
                Authorization: `Bearer ${token}`,
                room: room,
            }
        })
            .then(({ data }) => data)
            .catch((error) => {
                console.error("Une erreur s'est produite lors de la récuperation de l\'object image punch :", error);
                throw error;
            });

    }

}