import React, { useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { LinearGradient } from 'react-text-gradients';
import Pay from './Pay';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const MessageFromB = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const BotMessage = ({ message }) => {
        return (
            <div style={{ color:'black',textAlign: 'left', margin: '10px', padding: '10px', backgroundColor: '#e0e0e0' }}>
                <strong>Bot:</strong> {message}
            </div>
        );
    };

    const generateBotMessage = () => {
        return `Ceci est un message bot. Horodatage : ${new Date().toLocaleTimeString()}`;
    };

    const [botMessage, setBotMessage] = useState(generateBotMessage);

    useEffect(() => {
        /*
        // Définir un intervalle pour mettre à jour le message toutes les 5 secondes (ajustez selon vos besoins)
        const intervalId = setInterval(() => {
            const newMessage = generateBotMessage();
            setBotMessage(newMessage);
        }, 5000);

        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(intervalId);
        */
    }, []); // Déclenché une seule fois au montage

    const MessageSysteme = () => {
        return (
            <>
                <div style={{ fontSize: '20px', padding: '4px', borderRadius: '4px', width: '100%', textAlign: 'center' }}>
                
                        <BotMessage message={botMessage} />
                   
                </div>
            </>
        );
    };

    return (
        <div key={props.index + 1} style={{ display: 'flex', justifyContent: 'center' }} >
            <div key={props.index + 2} style={{ flex: '0 0 100%' }}>
                <MessageSysteme />
            </div>
            <Pay ref={ref} />
        </div>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageFromB);
