import { useState, useEffect, useRef, Fragment } from 'react';
import { Menu, Dropdown } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { HomeOutlined, UserOutlined, ShoppingCartOutlined, LogoutOutlined, DownOutlined ,VideoCameraOutlined ,MoreOutlined} from '@ant-design/icons';


function Header(props) {
    const url = useLocation().pathname

    const hamburgerRef = useRef(false)
    const [myLanguageState, setMyLanguageState] = useState('francais')

    const changeLanguage = (value) => {
        setMyLanguageState(value)
    }
    const toggleBugerMenu = () => {
        const slideHambuger = document.getElementById("primary-navigation")
        if (!hamburgerRef.current && slideHambuger) {
            slideHambuger.setAttribute("data-visible", "");
            hamburgerRef.current = true
        }
        else {
            slideHambuger.removeAttribute("data-visible")
            hamburgerRef.current = false
        }
    }

    const menu_pages = [
        { page: "Accueil", route: "/", class: 'active', target: '_self' },
        { page: "Tarif", route: "/price", class: '', target: '_self' },
        { page: "À propos", route: "/about", class: '', target: '_self' },
        { page: "Contact", route: "/contact", class: '', target: '_self' },
        { page: "Démo", route: "/tchat/1001", class: '', target: '_blank' },
    ];

    const language = [
        { language: "English", prefix: "en", class: 'null', flagUrl: '' },
        { language: "Francais", prefix: "ca", class: 'null', flagUrl: '' },
    ];

    const responsiveMenuIcon = <MoreOutlined />;

    const menu = (
        <>
            {language.map((obj, key) => (
                <Menu.Item key={key}>
                    <a onClick={() => changeLanguage(obj.language)} value={obj.language} className={obj.prefix}>
                        {obj.language}
                    </a>
                </Menu.Item>
            ))}
        </>
    );

    return (
        
        <Menu mode="horizontal" overflowedIndicator={responsiveMenuIcon}>
       <Menu.Item  key ="001" style={{width:'250px',textAlign:'left' , alignContent:'center',display:'flex'}} icon={<VideoCameraOutlined style={{fontSize:'40px'}} />}>
      Videochat ( {props.pageSelected}) 
       </Menu.Item>
            {menu_pages.map((obj, key) => (
                <Menu.Item key={key} icon={<UserOutlined />}>
                    <Link className={url === obj.route ? 'active' : 'null'} to={obj.route} target={obj.target}>{obj.page}

                    </Link>
                </Menu.Item>
            ))}
            <Menu.Item key="key" icon={<UserOutlined />}>
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        Langs <DownOutlined />
                    </a>
                </Dropdown>
            </Menu.Item>
        </Menu>
       
    );
}
export default Header;