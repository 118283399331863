import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Switch, Input, Image } from 'antd';
import { AndroidOutlined, AppleOutlined, ConsoleSqlOutlined, SettingOutlined } from '@ant-design/icons';
import { ConversationList, TypingIndicator, MessageInput, Avatar, MessageList, Message, MessageSeparator, Conversation, ConversationHeader, Sidebar } from '@chatscope/chat-ui-kit-react';
import { connect } from 'react-redux';
import InformRoomUserEntrance from './InformRoomUserEntrance';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { LinearGradient, RadialGradient } from 'react-text-gradients'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Pay = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {



    const showModal = () => {
        ref.setOpenModal(prevState => ({
            ...prevState,
            isModalOpenPay: true,
        }));
    };
    const handleOk = () => {
        ref.setOpenModal(prevState => ({
            ...prevState,
            isModalOpenPay: false,
        }));
    };
    const handleCancel = () => {
        ref.setOpenModal(prevState => ({
            ...prevState,
            isModalOpenPay: false,
        }));
    };


    return (
        <>

            <Modal title="Payer" open={ref.OpenModal.isModalOpenPay} onOk={handleOk} onCancel={handleCancel}>
            <span>Tchat sans shoutcast 30$</span>
                <div>
                    <Button type="primary" onClick={showModal}>
                        Payer via Interac
                    </Button>
                </div>
                <div style={{paddingTop:'5px'}}>
                    <Button type="primary" onClick={showModal}>
                        Payer via Paypal
                    </Button>
                </div>

                <span>Tchat avec shoutcast 55$</span>
                <div>
                    <Button type="primary" onClick={showModal}>
                        Payer via Interac
                    </Button>
                </div>
                <div style={{paddingTop:'5px'}}>
                    <Button type="primary" onClick={showModal}>
                        Payer via Paypal
                    </Button>
                </div>
            </Modal>
        </>

    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Pay);