import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Input, Select, Avatar, Form, Checkbox, Switch, Table, Upload } from 'antd';
import { MenuUnfoldOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import {  adminAddRoleApi } from '../../../../endpoints/admin'
const { Option } = Select;

const { Column, ColumnGroup } = Table;

const AddRole = forwardRef(({ app, testStore, designPage, user, onSearch, ...props }, ref) => {
  const [form] = Form.useForm();
  const [dataa, setDataa] = useState([])
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleSelect = (value) => {
    setSearchValue(value);
    onSearch(value); // Appeler la fonction de recherche avec la valeur sélectionnée
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    adminAddRoleApi(values, user?.token, user?.room, user?.socketId).then(resp => {
      if (resp) {
     
        props.setCurrentComponentObj({ name: 'Room' ,object: resp.roleObj })
      }
      form.resetFields();
    })
  };

  const onFinishFailed = (errorInfo) => { 
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
 
  }, [])

  return (

    <>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          textAlign: 'start',
          marginTop: '10px',

        }}

        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >


        <Form.Item
          className="custom-form-item"
          label="Title role"
          name="role"
          rules={[
            {
              required: true,
              message: 'Please input your title role!',
            },

          ]}
        >
          <Input />
        </Form.Item>

       

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 24,
          }}
        >
          <Button type="primary" htmlType="submit">
            Ajouter
          </Button>
        </Form.Item>

      </Form>
    </>
  );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(AddRole);
