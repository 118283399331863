import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { NotificationOutlined, SoundOutlined, MenuUnfoldOutlined, VideoCameraAddOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, SettingOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { } from '@chatscope/chat-ui-kit-react';
import { Col, Row, Typography, Radio, Button } from 'antd';
import { SocketContext } from '../../SocketContext';
import imageCompression from 'browser-image-compression';
import IndexAdminMenu from '../../adminMenu/Index';
import ModalUserOptions from './ModalUserOptions'
import useRefs from '../../ref/useRefs';
import { avatar } from '../../../../endpoints/avatar';
import Gift from '../Gift/Gift';

const { Text, Link } = Typography;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Middle = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const refs = useRefs();
    const socket = useContext(SocketContext);
    const userOpenModalRef = {}
    const userCloseModalRef = {}
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    let sizeOfImage = 99999999999999;
    const mounteImagedRef = useRef(false)
   
    const [videoEnableDisabled, setEnableVideoDisabled] = useState(false)
    const [audioEnableDisabled, setEnableAudioDisabled] = useState(false)
    const [isModalOpenGift, setIsModalOpenGift] = useState(false);
    const [gift , setGift] = useState();
   
    const show_hideRoomSelect = () => {
        setIsModalOpen(true)
    }

    const sendAvatar = () => {
        fileInputRef.current.click();
    };

    const receiveBeer =(gift) =>{
        setIsModalOpenGift(true)
        setGift(gift)
    }

    const updateUserToken =(value) =>{
        ref.setTemplateTexteColorState(prevState => ({
            ...prevState,
            ['token_virtual']: value
        }));
       
        props?.dispatch(setUserApp({ token_virtual: value }))
        
    }
    

    useEffect(() => {
        if (socket) {
            socket.on('blockVideo', blockVideo);
            socket.on('blockAudio', blockAudio);
            socket.on('unBlockVideo', unblockVideo);
            socket.on('unBlockAudio', unBlockAudio);
            socket.on('sendGift', receiveBeer);
            socket.on('updateUserToken', updateUserToken);
            
        }

    }, [socket]);

    useEffect(()=>{
       //donc je peut recevoir tout par ref 
       //donc je pourrais creer un component lui passer ref et faire jouer le MP3 par le nom de ref.soundRoom
        if(ref.SoundEffect){
        
        }
    },[ref.SoundEffect])

    const blockVideo = () => {
        setEnableVideoDisabled(true)
    }

    const unblockVideo = () => {
        setEnableVideoDisabled(false)
    }

    const blockAudio = () => {
        setEnableAudioDisabled(true)
    }

    const unBlockAudio = () => {
        setEnableAudioDisabled(false)
    }

    const statuss = () => {
        props?.dispatch(setUserApp({ status: 'online' }))
    }
 
    const toggleUserList = () => {
        props?.dispatch(setUserApp({ userlist: !user?.userlist }))
    }
 

    const [isModalOpen, setIsModalOpen] = useState(false);
 


    return (
        <>
        {/* ouvre la fenetre gift */}
        <Gift gift={gift} setIsModalOpenGift={setIsModalOpenGift} isModalOpenGift={isModalOpenGift}  ref={ref} />
            <ModalUserOptions ref={ref} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

            <Col ref={ref.logo} style={{ color: 'black', backgroundRepeat: 'no-repeat', backgroundSize: 'auto 100%' }} xs={0} sm={0} lg={8}>
                {/* logo s'affichera ici depuis la reference */}
            </Col>

            <Col xs={7} sm={7} lg={8} >
                <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} icon={<SettingOutlined style={{ fontSize: '15px', color: 'white', height: '15px' }} />} onClick={show_hideRoomSelect} ><span style={{ color: 'white' }}>{user?.username}</span></Button>
            </Col>

            <Col style={{ textAlign: 'right', color: 'black', height: '' }} xs={17} sm={17} lg={8} >

                <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} icon={user?.userlist ? <MenuFoldOutlined style={{ fontSize: '20px', color: 'white' }} /> : <MenuUnfoldOutlined style={{ fontSize: '20px', color: 'white', backgroundColor: 'black' }} />} onClick={toggleUserList} ></Button>

                {user?.role?.open_webcam === 1 ?
                    <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} disabled={videoEnableDisabled} border icon={<VideoCameraAddOutlined ref={props.localwebcamRef} style={{ fontSize: '20px', color: 'white' }} />} onClick={props.toggleWebcam}></Button>
                    : null
                }

                {user?.role?.open_micro === 1 ?
                    <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} disabled={audioEnableDisabled} border icon={<AudioOutlined ref={props.localMicroRef} style={{ fontSize: '20px', color: 'white' }} />} onClick={props.toggleAudio}></Button>
                    : null
                }

                {user?.role?.open_share_screen === 1 ?
                    <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} icon={<DesktopOutlined style={{ fontSize: '20px', color: 'white' }} />} onClick={props.enableScreenVideoTrack}></Button>
                    : null
                }
                <Button style={{ backgroundColor: 'transparent', margin: '5px 5px 0 0' }} icon={<ApiOutlined style={{ fontSize: '20px', color: 'white' }} />}></Button>
            </Col>

            
        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Middle);
