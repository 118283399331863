import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, Switch, Input, Image } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllPunchImageApi, adminPunchSetActiveImageApi, adminPunchDeleteImageApi } from '../../../../endpoints/admin'
import ModalShowRespondFromApi from './ModalShowRespondFromApi';

import ModalShowImagePunchInformation from './ModalShowImagePunchInformation';
const { Column, ColumnGroup } = Table;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Punch = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const [dataa, setDataa] = useState(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [originalData, setOriginalData] = useState([])
    const [isModalOpenFileInfo, setIsModalOpenFileInfo] = useState({ show: false, obj: null });
    const [forceUpdate, setForceUpdate] = useState(null);
    const [message, setMessage] = useState(null)
    const [modalRespondApi, setModalRespondApi] = useState(false)


    const handleSearch = (e) => {
        const searchValue = e.target.value;
        const filteredRooms = originalData.filter((image) =>
            image.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setDataa(filteredRooms);
        setSearchTerm(searchValue);
    };

    useEffect(() => {
        adminGetAllPunchImageApi(user?.token, user?.room, user?.socketId).then(resp => {
            if (resp.image) {
                setDataa(resp.image)
                setOriginalData(resp.image)
            }
        })
    }, [forceUpdate])

    const handleSwitchChange = (targetId) => {
        adminPunchSetActiveImageApi(user?.token, user?.room, user?.socketId, user?.username, targetId).then(resp => {
            if (resp.permission) {
                setDataa((prevState) =>
                    prevState.map((obj) => ({
                        ...obj,
                        active: obj.id === targetId ? 1 : 0,
                    }))
                );
            }
            else{
                setMessage(resp.message)
                setModalRespondApi(true)
            }
        })


    };

    const showModalInfo = (record) => {
        setIsModalOpenFileInfo({ show: true, obj: record })
    }

    const deletePunchImage = (targetId) => {
        adminPunchDeleteImageApi(user?.token, user?.room, user?.socketId, targetId).then(resp => {
            if (!resp.permission) {
                setMessage(resp.message)
                setModalRespondApi(true)
            }
            else {
                setForceUpdate(new Date());
            }
        })
    }

    return (
        <>
            <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />

            <Row>
                <Col span={24} style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="text"
                        placeholder="Rechercher une image Punch"
                        value={searchTerm}
                        style={{ width: '100%' }}
                        onChange={handleSearch}
                    />
                    <Button size="large" onClick={() => props.setCurrentComponentObj({ name: 'addPunch' })} style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }} type="primary" >+Ajouter</Button>
                </Col>
            </Row>

            <Table dataSource={dataa}>

                <Column
                    title="Image"
                    dataIndex="file_name"
                    key="file_name"
                    render={(file_name) => (
                        <Image
                            src={`${URL}/uploads/${file_name}`}
                            shape="square"
                            alt="logo"
                            size={64}
                        />
                    )}
                />

                <Column title="Nom" dataIndex="name" key="name" />

                <Column
                    title="Action"
                    key="action"
                    render={(_, record) => (
                        <Space size="middle">
                            <Button onClick={() => deletePunchImage(record.id)} type="primary" danger>Supprimer</Button>
                            <Button style={{ backgroundColor: '#14A44D' }} onClick={() => showModalInfo(record)} type="primary" danger>Information</Button>
                        </Space>
                    )}
                />

                <Column
                    title="active"
                    dataIndex="active"
                    key="active"
                    render={(active, record) => (
                        <>
                            <Switch
                                checked={active === 1}
                                onChange={() => handleSwitchChange(record.id)}
                            />
                        </>
                    )}
                />

                {/*}
            <Column
                title=<Button onClick={() => props.setCurrentComponentObj({ name: 'addRoom' })} style={{ position: 'absolute', left: '-100px', bottom: '10px' }} type="primary" >Ajouter</Button>
                key="add"
            />
                {*/}
            </Table>
            <ModalShowImagePunchInformation isModalOpenFileInfo={isModalOpenFileInfo} setIsModalOpenFileInf={setIsModalOpenFileInfo} />
        </>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Punch);