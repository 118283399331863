import { MenuUnfoldOutlined, VideoCameraOutlined, MenuFoldOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, theme, Badge, Col, Row } from 'antd';
import { useState, useRef, forwardRef, useEffect } from 'react';
import Onglet from '../onglet/Onglet'
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import InputChat from '../InputChat/InputChat'
import UserList from '../rightSide/UserLIst'
import ModalMessageFromServer from '../modal/ModalMessageFromServer'
import FlashMessageFromServer from '../rightSide/FlashMessageFromServer'

//import IndexAdminMenu from '../../adminMenu/Index'
import useRefs from '../../ref/useRefs'
const { Header, Sider, Content } = Layout;

const Center = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
    const refs = useRefs();
    const flashMessageRef = useRef()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const messagesEndRef = useRef(null);
    const [collapsed, setCollapsed] = useState(false);
    //const [adminPanelVisible, setAdminPanelVisible] = useState(true)

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (user?.messageFromServeur) {

            setIsModalOpen(true);
        }
    }, [user?.messageFromServeur]);

    return (
        <>

            <Layout style={{ padding: 0, margin: 0 }}>
                <Header
                    style={{
                        margin: 0,
                        padding: 0,
                        background: colorBgContainer,
                    }}  
                >
                </Header>
                <Content
                    style={{
                        background: colorBgContainer,
                       
                    }}
                >
                    <div ref={messagesEndRef} >
                        <Onglet ref={ref} messagesEndRef={{ messagesEndRef }} />
                        <div>
                            <FlashMessageFromServer flashMessageRef={flashMessageRef} />
                        </div>
                    </div>
                    <div ref={ref.bottonBox}>
                        <InputChat ref={ref} />
                    </div>
                </Content>
                <UserList ref={ref} startVideoProducer={props.startVideoProducer} flashMessageRef={flashMessageRef} />
            </Layout>

            <ModalMessageFromServer isModalOpen={isModalOpen} closeModal={closeModal} message={user?.messageFromServeur} />
            {   /*        <IndexAdminMenu adminPanelVisible={adminPanelVisible}  setAdminPanelVisible={setAdminPanelVisible} />
*/}        </>
    );
});
const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Center);