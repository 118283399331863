import { SET_INIT, SET_TEST, SET_USER , SET_MESSAGE ,SET_DESIGNPAGE } from '../constant/constant';

const app = (state = {}, action) => {
    
    switch (action.type) {
        case SET_INIT:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const user = (state = {}, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const actionMessage = (state = {}, action) => {
    switch (action.type) {
        case SET_MESSAGE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const designPage = (state = {}, action) => {
   
    switch (action.type) {
        case SET_DESIGNPAGE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

const testStore = (state = { socketID: 'mon test store' }, action) => {

    switch (action.type) {
        case SET_TEST:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export { app, testStore, user , actionMessage ,designPage}