import { useContext, useState, useEffect, useRef, forwardRef } from 'react';
import { setUserApp } from '../../action/action';
import { connect } from 'react-redux';
import { SocketContext } from './SocketContext';
import { Col, Row } from 'antd';
import Player from '../tchat/elements/player/Player'
import Banner from '../tchat/elements/banner/Banner'
import Center from '../tchat/elements/center/Center'
import Middle from '../tchat/elements/middle/Middle'
import sendRequest from '../socket/requestModule'
import audioVideoTools from '../../audioTools/audioTools'
//import { loader } from '../../mediasoup/mediasoup'

import { Device } from 'mediasoup-client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import JspanelCam from "../tchat/elements/jsPanel/JsPanel"
import { listAllEventListeners } from '../utils/ListAllEventListeners'
import { join_room } from './chatUtils/utils';
import useRefs from './ref/useRefs'
import RefControler from './ref/RefControler';
import { EventEmitter } from '../utility/Emitter'
import { async } from 'react-input-emoji';


const InterfaceChat = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {


    const refs = useRefs();

    //audio & video setting
    let device = useRef(null)
    let audioContext = null;
    let audioFftArray = null;
    let id = null;
    let audioAnalysers = {};
    let audioConsumers = useRef({})
    let audioConstraints = null;
    let videoConstraints = null;
    let producerTransport = useRef({})
    let consumerTransport = useRef({})
    let videoTrack = useRef(null)
    let videoProducer = useRef(null)
    let videoScreenShare = useRef(null)
    let socket = useContext(SocketContext);
    let videoEnabled = useRef(null)
    let videoConsumers = useRef({})
    const username = useRef(null)
    let audioEnabled = useRef(false)
    let audioTrack = useRef(null)
    let audioProducer = useRef(null)
    let consumer = useRef(null)
    const [renderJspanelCam, setRenderJspanelCam] = useState([]);

    let micro = useRef(false)
    let webcam = useRef(false)
    const localwebcamRef = useRef();
    const localMicroRef = useRef();

    const toggleWebcam = async () => {
        if (!webcam.current) {
            await enableLocalVideo();
            localwebcamRef.current.style.color = "green";
            webcam.current = true
        } else {
            disableLocalVideo();
            localwebcamRef.current.style.color = "white";
            webcam.current = false
        }
    };

    const toggleAudio = async () => {
        if (!micro.current) {
            localMicroRef.current.style.color = "green";
            micro.current = true
            await enableLocalAudio();
        } else {
            stopAudioProducer();
            localMicroRef.current.style.color = "white";
            micro.current = false
        }

    };

    const starting = async () => {

        let isConnected = true
      //  audioVideoTools.initializeAudioTools(audioContext, audioFftArray, id, audioAnalysers);
      //  audioVideoTools.initializeDeviceOptions(audioConstraints, videoConstraints);
        const routerRtpCapabilities = await sendRequest(socket, 'getRouterRtpCapabilities', {});

        device.current = new Device();
        await device.current.load({ routerRtpCapabilities });


        // device.current = await loader(routerRtpCapabilities)


        if (!producerTransport.current.on) {
            let params = await sendRequest(socket, 'createProducerTransport', {});
            producerTransport.current = await device.current.createSendTransport(params);
            console.log('producerTransport ready')
        }
        if (!consumerTransport.current.on) {
            let params = await sendRequest(socket, 'createConsumerTransport', {});
            consumerTransport.current = await device.current.createRecvTransport(params);
            console.log('consumerTransport ready')
        }
        //init with mediasoup listener
        setupTransportListeners();

        let currentInfo;
        try {
            currentInfo = await sendRequest(socket, 'getCurrentRemoteInfo', { localId: socket.id });

            //todo : envoyer le array de user dans userliste
            currentInfo.remoteUsers.forEach(user => {
               
                //   addUser(user.id, user);

            });
            //ouvre les cams automatiquement quand on entre dans le salon

            currentInfo.remoteVideoIds.forEach(rId => {
                //  const addConsumer = async (transport, remoteSocketId, prdId, trackKind, username)
                //addConsumer(consumerTransport.current, props.consumeIdWebcam[0], null, 'video', name);

                currentInfo.remoteUsers.forEach(userEach => {
                    if (userEach.socketId === rId) {
                        const username = userEach.username
                        addConsumer(consumerTransport, rId, null, 'video', username);
                    }
                });


            });

            currentInfo.remoteAudioIds.forEach(rId => {

                // addConsumer(consumerTransport, rId, null, 'audio');
            });

        } catch (error) {
            console.error(`[connect] [getCurrentRemoteInfo] mensagem de erro: ${error}`);
        }
        isConnected = true
        // updateUI();
        console.log('Socket IO Connection estabilished.');
        //await enableLocalAudio()
        //await enableScreenVideoTrack()
    }

    useEffect(() => {

            props?.dispatch(setUserApp({ addConsumer: addConsumer, addConsumerPrivate: addConsumerPrivate }))
            if (socket) {
                const init = async () => {
                  //  socket.emit('hello', 'hello world')
                    socket.on('connect', (e) => join_room({ ...e, ...props, socket, user} ,user?.fpHash));
                    socket.on('removeJspanel', removeJSpanel);
    
                    // socket.on('disconnect' , reset ) 
                    await starting()
                }
    
                init().then(() => {
    
                })
    
                return () => {
                    // socket.off('join_room', join_room);
                }
            };
        
       
    }, [socket]);

    const setupTransportListeners = () => {

        const openWebcamForUserPrivate = async () => {
            await enableLocalVideoPrivate()
            startVideoProducerPrivate()
        }
        const closeWebcamForUserPrivate = (username) => {
            const videoElement = document.getElementById(username);
            if (videoElement) {
                videoElement.pause();
                videoElement.srcObject = null;
            }

            disableLocalVideo()
            videoTrack.current = null;
        }
        const emitterMessage = EventEmitter().subscriber('private', data => {

            const username = data.username
            if (!videoEnabled.current) {

                openWebcamForUserPrivate()
            }
            else {

                closeWebcamForUserPrivate(username)
            }

        })

        try {
            if (producerTransport) {
                producerTransport.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
                    sendRequest(socket, 'connectProducerTransport', { dtlsParameters: dtlsParameters })
                        .then(callback)
                        .catch(errback);
                });

                producerTransport.current.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
                    try {
                        const { id } = await sendRequest(socket, 'produce', {
                            transportId: producerTransport.current.id,
                            kind,
                            rtpParameters

                        });

                        callback({ id });

                    } catch (err) {
                        errback(err);
                    }
                });

                producerTransport.current.on('privateProduce', async ({ kind, rtpParameters }, callback, errback) => {
                    try {
                        const { id } = await sendRequest(socket, 'privateProduce', {
                            transportId: producerTransport.current.id,
                            kind,
                            rtpParameters,
                            testt: 'privateeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

                        });

                        callback({ id });

                    } catch (err) {
                        errback(err);
                    }
                });


                producerTransport.current.on('connectionstatechange', (state) => {
                    switch (state) {
                        case 'connecting':
                            console.log('producerTransport connecting...');
                            break;
                        case 'connected':
                            console.log('producerTransport connected.');
                            break;
                        case 'failed':
                            console.log('producerTransport connection failed.');
                            producerTransport.current.close();
                            break;

                        default:
                            break;
                    }
                });
            } else {
                console.error('[setupTransportListeners] ProducerTransport n\'a pas été initialisé (l\'application plantera probablement');
            }

            if (consumerTransport) {
                consumerTransport.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
                    sendRequest(socket, 'connectConsumerTransport', { dtlsParameters: dtlsParameters })
                        .then(callback)
                        .catch(errback);
                });


                consumerTransport.current.on('connectionstatechange', (state) => {
                    switch (state) {
                        case 'connecting':
                            console.log('consumerTransport connecting...');
                            break;
                        case 'connected':
                            console.log('consumerTransport connected.');
                            break;
                        case 'failed':
                            console.log('consumerTransport connection failed.');
                            consumerTransport.current.close();
                            break;

                        default:
                            break;
                    }
                });
            } else {
                console.error('[setupTransportListeners] consumerTransport n\'a pas été initialisé (l\'application va probablement planter');
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function addUser(id, user) {
        if (!user.isGhost) {
            //let userFeedCheck = $(`.user-feed[data-id="${id}"]`);
        }
    }

    const enableLocalVideo = async () => {

        if (videoProducer.current) {
            alert('exist')
            videoProducer.current.close();
            videoProducer.current = null;
            await sendRequest(socket, 'stopVideoProducer', {});
            removeJSpanel("local", "video")
            videoTrack.current = null
            videoEnabled.current = null
        }

        if (!videoEnabled.current) {
            //props.localWebcamUserRef.current.style.backgroundColor = "green";
            await enableLocalVideoTrack()
            props?.dispatch(setUserApp({ videotrack: videoTrack.current }))

            setRenderJspanelCam(component => ([...component, <JspanelCam toggleWebcam={toggleWebcam} toggleAudio={toggleAudio} key={'local'} removeJspanel={disableLocalVideoTrack} webcam={webcam} micro={micro} cam={{ id: 'local', track: videoTrack.current, username: user?.username }} />]))
            await startVideoProducer();
            videoEnabled.current = true;
        }
    }

    const enableLocalVideoPrivate = async () => {
        if (!videoEnabled.current) {
            //props.localWebcamUserRef.current.style.backgroundColor = "green";
            await enableLocalVideoTrack()
            props?.dispatch(setUserApp({ videotrack: videoTrack.current }))

            await startVideoProducer();
            videoEnabled.current = true;
        }
    }


    async function disableLocalVideo() {
        if (videoEnabled.current) {

            await stopVideoProducer();

            disableLocalVideoTrack();
            // isScreensharing = false;
            videoEnabled.current = false;
        }
    }

    async function stopVideoProducer() {

        if (videoProducer.current) {
            videoProducer.current.close();
            videoProducer.current = null;
            await sendRequest(socket, 'stopVideoProducer', {});
        }
    }

    function disableLocalVideoTrack() {
        if (videoTrack.current) {
            // videoTrack.current.stop();
            videoTrack.current = null;
            const jsPanel = document.getElementById("jsPanel_local")
            if (jsPanel) {
                //  jsPanel.close()
                removeJSpanel("local", "video")
            }

        } else {
            console.warn('[disableLocalVideoTrack] videoTrack n\'existe pas (ne devrait poser aucun problème)');
        }
    }

    const enableLocalVideoTrack = async () => {


        if (!videoTrack.current) {
            let stream = null;
            let localVideo = document.getElementById('local_video')
            try {
                stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
                if (stream) {
                    let tracks = stream.getVideoTracks();
                    if (tracks && tracks.length) {
                        videoTrack.current = tracks[0];

                        for (let i = 1; i < tracks.length; i++) {
                            tracks[i].stop();
                            tracks[i] = null;
                        }
                        tracks = null;
                        stream = null;
                    }
                }
            } catch (error) {
                videoTrack.current = null;
                console.error(`[enableLocalVideoTrack] la caméra n'a pas pu être activée. Message d'erreur ${error}`);
            }
        }

    }
    function disableLocalVideoTrack() {
        if (videoTrack.current) {
            videoTrack.current = null;
            const jsPanel = document.getElementById("jsPanel_local")
            if (jsPanel) {
                removeJSpanel("local", "video")
            }
        } else {
            console.warn('[disableLocalVideoTrack] videoTrack n\'existe pas (ne devrait poser aucun problème)');
        }
    }


    const enableScreenVideoTrack = async () => {

        if (videoProducer.current) {
            videoTrack.current.stop()
            disableLocalVideo();
            localwebcamRef.current.style.color = "white";
            webcam.current = false

        }

        if (!videoTrack.current) {
            let stream = null;
            let localVideo = document.getElementById('local_video')
            try {
                let stream = await navigator.mediaDevices.getDisplayMedia({ audio: false, video: true });

                if (stream) {
                    let tracks = stream.getVideoTracks();
                    if (tracks && tracks.length) {
                        videoTrack.current = tracks[0];

                        for (let i = 1; i < tracks.length; i++) {
                            tracks[i].stop();
                            tracks[i] = null;
                        }
                        tracks = null;
                        stream = null;
                    }
                }

                setRenderJspanelCam(component => ([...component, <JspanelCam toggleWebcam={toggleWebcam} toggleAudio={toggleAudio} key={'local'} removeJspanel={disableLocalVideoTrack} webcam={webcam} micro={micro} cam={{ id: 'local', track: videoTrack.current, username: user?.username }} />]))
                await startVideoProducer();
                videoEnabled.current = true;


            } catch (error) {
                videoTrack.current = null;
                console.error(`[enableLocalVideoTrack] la caméra n'a pas pu être activée. Message d'erreur ${error}`);
            }
        }

    }


    const startVideoProducer = async (value) => {

        if (!videoProducer.current && videoTrack.current) {
            try {
                videoProducer.current = await producerTransport.current.produce(
                    {
                        track: videoTrack.current,
                        encodings: [
                            { maxBitrate: 100000 }
                        ],
                        oneToOne: value

                    }
                );

                /*
                        setTimeout(async() => {
                            
                              let params = await sendRequest(socket, 'pauseProducer', {kind:'video'});
                            
                        }, 20000);
                
                        setTimeout(async() => {
                            
                            let params = await sendRequest(socket, 'resumeProducer', {kind:'video'});
                          
                      }, 25000);
                */

                console.log('Started videoProducer');
            } catch (error) {
                console.error(`[startVideoProducer] La vidéo de la caméra n'a pas pu être envoyée au serveur. Message d'erreur: ${error}`);
            }
        }
    }

    const startVideoProducerPrivate = async (value) => {

        if (!videoProducer.current && videoTrack.current) {
            try {
                videoProducer.current = await producerTransport.current.privateProduce(
                    {
                        track: videoTrack.current,
                        encodings: [
                            { maxBitrate: 100000 }
                        ],
                        oneToOne: '21212121211221212121'

                    }
                );

                console.log('Started videoProducer');
            } catch (error) {
                console.error(`[startVideoProducer] La vidéo de la caméra n'a pas pu être envoyée au serveur. Message d'erreur: ${error}`);
            }
        }
    }

    async function enableLocalAudio() {
        if (!audioEnabled.current) {
            //  props.localMicroUserRef.current.style.backgroundColor = "green";
            await enableLocalAudioTrack();
            await startAudioProducer();
        }
    }

    const enableLocalAudioTrack = async () => {
        if (!audioTrack.current) {
            let stream = null;
            try {
                stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                if (stream) {
                    let tracks = stream.getAudioTracks();
                    if (tracks && tracks.length) {
                        audioTrack.current = tracks[0];

                        for (let i = 1; i < tracks.length; i++) {
                            tracks[i].stop();
                            tracks[i] = null;
                        }
                        tracks = null;
                        stream = null;
                    }
                }
            } catch (error) {
                audioTrack.current = null;
                console.error(`[enableLocalAudioTrack] Le microphone n'a pas pu être activé. Message d'erreur: ${error}`);
            }
        }
    }

    async function startAudioProducer() {
        if (!audioProducer.current && audioTrack.current) {
            try {
                audioProducer.current = await producerTransport.current.produce(
                    {
                        track: audioTrack.current,
                        encodings: [
                            { maxBitrate: 7500 }
                        ]
                    }
                );

                console.log('Started audioProducer');
            } catch (error) {
                console.error(`[startAudioProducer] L'audio du microphone n'a pas pu être envoyé au serveur. Message d'erreur: ${error}`);
            }
        }
    }

    async function stopAudioProducer() {
        if (audioProducer.current) {
            //   props.localMicroUserRef.current.style.backgroundColor = "transparent";
            audioProducer.current.close()
            audioProducer.current = false;
            audioTrack.current = null
            await sendRequest(socket, 'stopAudioProducer', {});
        }
    }

    const addConsumer = async (transport, remoteSocketId, prdId, trackKind, username, socketContext) => {
        if (!socket) {
            socket = socketContext;
        }
        const { rtpCapabilities } = device.current;
        const data = await sendRequest(socket, 'consumeAdd', {
            rtpCapabilities: rtpCapabilities,
            remoteId: remoteSocketId,
            kind: trackKind
        })
            .catch(error => {
                console.error(`[addConsumer] consumeAdd retornou um erro: ${error}`);
            });

        let {
            producerId,
            id,
            kind,
            rtpParameters,
            isTeacher
        } = data;


        if (prdId && (prdId !== producerId)) {
            console.warn('[addConsumer] producteurId ne correspond pas, problème de synchronisation de serveur possible (problématique, ne devrait pas se produire');
        }

        let codecOptions = {};

        const consumer = await consumerTransport.current.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions
        });
        console.log(consumer)
        if (consumer.on) {

            consumer.on("@close", () => {
                console.log('close comsumer' + producerId);
            });

            consumer.on("@pause", () => {
                alert("Le consommateur a mit sur pause.");
            });

            consumer.on("@producerclosed", () => {
                alert("Le producteur a été producercloseD.");
            });

            consumer.on("@producerclose", () => {
                alert("Le producteur a été producerclose.");
            });

            consumer.on("@resume", () => {
                alert("Le consommateur a resume.");
            });

            consumer.on("@trackended", () => {
                alert("Le consommateur a trackended.");
            });

            consumer.on("@transportclose", () => {
                alert("Le transportclose a été fermé.");
            });




            consumer.on('@trackended', () => {
                alert('trackended');
                //removeConsumer(consumer.remoteId, kind);
            });


        }


        /*
                setTimeout(() => {
                    consumer.pause()
                }, 5000);
        
                setTimeout(() => {
                    consumer.resume()
                }, 10000);
        */

        if (kind === 'video') {
            props?.dispatch(setUserApp({ userConsumer: { id: remoteSocketId, consumer: consumer } }));

            videoConsumers.current[remoteSocketId] = consumer;
        } else if (kind === 'audio') {
            audioConsumers.current[remoteSocketId] = consumer;
        } else {
            console.error(`[addConsumer] . (erreur critique, résultat imprévisible)`);
        }
        consumer.remoteId = remoteSocketId;



        if (kind === 'video') {
            sendRequest(socket, 'resumeAdd', { remoteId: remoteSocketId, kind: kind })
                .then(() => {
                    // console.log('resumeAdd OK');
                })
                .catch(err => {
                    console.error(`[addConsumer] erreur dans l'action resumeAdd (l'utilisateur n'aura plus de vidéo`);
                });
        }

        let elementjs = document.getElementById(`${remoteSocketId}`)
        if (elementjs) {
            // Récupérer la piste vidéo existante
            const videoElement = document.getElementById(`${remoteSocketId}`);
            const videoStream = videoElement.captureStream();
            const videoTrack = videoStream.getVideoTracks()[0];

            // Créer une nouvelle piste audio
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const audioTrack = audioStream.getAudioTracks()[0];

            // Ajouter la nouvelle piste audio à la piste vidéo existante
            videoStream.addTrack(consumer.track);

            // Mettre à jour la source vidéo avec la nouvelle piste audio
            videoElement.srcObject = videoStream;
            videoElement.muted = false
            videoElement.play();
            /*
                        audioTools.initializeAudioTools()
                        audioTools.createAudioAnalyser(remoteSocketId, videoElement.srcObject)
                        setInterval(() => audioTools.watchAudioTracks(), 100);
                        */
        }

        // Ajout une webcam unique par instance, et le fait d'avoir une seule et unique instance permet de ne pas avoir de re-render de la webcam
        if (kind === 'video' && !elementjs) {

            setRenderJspanelCam(component => ([...component, <JspanelCam key={remoteSocketId} removeJSpanel={removeJSpanel} cam={{ id: remoteSocketId, track: consumer.track, username: username }} />]))

            /*
                        let webcam = props.forwardedRef['IconeWebcamRef'][remoteSocketId]
                        if (webcam) {
                            webcam.style.display = "initial"
                        }
            */
        }

        if (kind === 'audio' && !elementjs) {
            setRenderJspanelCam(component => ([...component, <JspanelCam key={remoteSocketId} removeJSpanel={removeJSpanel} cam={{ id: remoteSocketId, track: consumer.track, username: username }} />]))

            // let audio = props.forwardedRef['IconeMicroRef'][remoteSocketId]
            if (micro.current) {

                micro.style.display = "initial"
            }
        }
    }

    const addConsumerPrivate = async (transport, remoteSocketId, prdId, trackKind, username, socketContext) => {

        if (!socket) {
            socket = socketContext;
        }
        const { rtpCapabilities } = device.current;
        const data = await sendRequest(socket, 'consumeAdd', {
            rtpCapabilities: rtpCapabilities,
            remoteId: remoteSocketId,
            kind: trackKind
        })
            .catch(error => {
                console.error(`[addConsumer] consumeAdd retornou um erro: ${error}`);
            });

        let {
            producerId,
            id,
            kind,
            rtpParameters,
            isTeacher
        } = data;

        if (prdId && (prdId !== producerId)) {
            console.warn('[addConsumer] producteurId ne correspond pas, problème de synchronisation de serveur possible (problématique, ne devrait pas se produire');
        }

        let codecOptions = {};

        consumer.current = await consumerTransport.current.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions
        });

        user?.updateConsumer({ id: id, consumer: consumer.current })
        //update fonction user and past consumer update({id:id , consumer: consumer })

        if (kind === 'video') {
            videoConsumers.current[remoteSocketId] = consumer.current;
        } else if (kind === 'audio') {
            audioConsumers.current[remoteSocketId] = consumer.current;
        } else {
            console.error(`[addConsumer] . (erreur critique, résultat imprévisible)`);
        }
        consumer.current.remoteId = remoteSocketId;

        if (!consumer.current.closed) {
            alert('on set les ecouteurs ')
            consumer.current.on("transportclose", () => {
                alert("transportclose");
            });

            consumer.current.on("producerclosed", () => {
                alert("producerclose");
            });

            consumer.current.on("close", () => {
                alert("producerclose");
            });

            consumer.current.on('trackended', () => {
                alert('trackended');
                //removeConsumer(consumer.remoteId, kind);
            });
        }
        if (kind === 'video') {
            sendRequest(socket, 'resumeAdd', { remoteId: remoteSocketId, kind: kind })
                .then(() => {
                    // console.log('resumeAdd OK');
                })
                .catch(err => {
                    console.error(`[addConsumer] erreur dans l'action resumeAdd (l'utilisateur n'aura plus de vidéo`);
                });
        }

    }

    const removeJSpanel = (id, kind) => {

        if (id === 'local') {
            // stopVideoProducer();
        }
        /*
                if (kind === 'video') {
                    videoEnabled.current = null
                }
        */
        if (kind === 'video' && id != 'local') {
            videoConsumers.current[id].close()
            socket.emit("stopConsume", id, socket.id, kind)
        }
        if (kind === 'audio') {
            audioEnabled.current = null
            audioConsumers.current[id].close()
            socket.emit("stopConsume", id, socket.id, kind)
        }
        let element = document.getElementById(`jsPanel_${id}`)
        if (element) {

            element.close()

            setRenderJspanelCam((prevComponent) => {
                return prevComponent.filter((item) => {
                    return item.key !== id;
                });
            });

        }
    }

    return (
        <>
            <div style={{ minHeight: '100vh', maxHeight: '100vh', backgroundColor: 'black' }}>
                <Row style={{ height: '73px' }}>
                    <Col style={{ height: '73px' }} span={12}>
                        <Player room={user?.room} />
                    </Col>
                    <Col style={{ height: '73px' }} span={12}>
                        <Banner />
                    </Col>
                </Row>
                {renderJspanelCam}
                { /* mediasoupTrack ? <TestTrack track={mediasoupTrack} /> : ''  */}
                <Row ref={refs.ZoneMiddleComponent} style={{ height: '7vh', ackgroundColor: 'white' }} >
                    <Middle ref={refs} enableScreenVideoTrack={enableScreenVideoTrack} toggleWebcam={toggleWebcam} toggleAudio={toggleAudio} localMicroRef={localMicroRef} localwebcamRef={localwebcamRef} />
                </Row>

                <Row >
                    <Col style={{ backgroundColor: 'pink', color: 'black' }} span={24} >
                        <Center ref={refs} startVideoProducer={startVideoProducer} />
                    </Col>
                    <RefControler ref={refs} />
                </Row>
            </div>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(InterfaceChat);
