import React, { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import imageCompression from 'browser-image-compression';

// import { uploadRoomLogo , uploadUserImage} from '../../../../endpoints/avatar'

const UploadFile = forwardRef(({ user, ...props }, ref) => {

    const [currentComponentObj, setCurrentComponentObj] = useState({ name: 'user' });
    const [visible, SetVisible] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    let sizeOfImage = 99999999999999;
    const mounteImagedRef = useRef(false)

    //const refs = useRefs();

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
            /*
            avatar(user?.token, user?.room, user?.socketId, formData).then(resp => {
                console.log('ok job done : avatar')
            })
            */

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
      

    }, [props.fileToSend])

    const handleFileSelect = (event) => {

        const data = event.target.files[0]
        if (data.type === 'image/png' || data.type === 'image/jpeg' || data.type === 'image/webp') {
            const fileName = data.name
            const compress = async data => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    //message reader.error
                }
                await imageCompression(data, { maxSizeMB: 1, useWebWorker: true }).then(res => {
                    if ((res.size / 1024).toFixed(0) === sizeOfImage && sizeOfImage > 400) {
                        //retourne un message d'erreur
                    }
                    if (sizeOfImage >= 400) {
                        res.name = fileName
                        mounteImagedRef.current = true
                        setSelectedFile(res)
                     
                    } else {
                        reader.readAsDataURL(res)
                    }
                    sizeOfImage = (res.size / 1024).toFixed(0);
                })
            }
            compress(data)
            event.target.value = ''
        }
        else if (data.type === 'image/gif') {
            mounteImagedRef.current = true
            setSelectedFile(data)
        }
        else {
            props?.dispatch(setMessageApp({ type: 'error', message: 'fichier refuser', notView: true }));
        }
    }

    const renderCurrentComponent = () => {
        switch (currentComponentObj.type) {
            case 'uploadRoomLogo':
            //proceed compression
            //  return <ListeUser setCurrentComponentObj={setCurrentComponentObj} />;

            case 'uploadUserImage':
            //proceed compression
            // return <ModifyUser ref={refs} setCurrentComponentObj={setCurrentComponentObj} currentComponentObj={currentComponentObj} />;

            default:
                return null;
        }
    };

    const onClose = () => {
        SetVisible(false)
    }

    return (
        <>div{props.fileToSend}</>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
