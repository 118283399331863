import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, Switch, Input, Image } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllPunchImageApi } from '../../../../endpoints/admin'
const { Column, ColumnGroup } = Table;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const ModalShowImagePunchInformation = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {



    const showModal = () => {
        props.setIsModalOpenFileInf({ show: false, obj: null });
    };
    const handleOk = () => {
        props.setIsModalOpenFileInf({ show: false, obj: null });
    };
    const handleCancel = () => {
        props.setIsModalOpenFileInf({ show: false, obj: null });
    };


    return (
        <Modal title="Informations sur la banniere" style={{ textAlign: 'center' }} open={props.isModalOpenFileInfo.show} onOk={handleOk} onCancel={handleCancel}>
            <Image
                src={`${URL}/uploads/${props.isModalOpenFileInfo.obj?.file_name}`}
                shape="square"
                alt="logo"
                size={64}
            />
            <Row style={{ textAlign: 'left' }}>
                <Col span={6}> nom :</Col><Col span={12}> {props.isModalOpenFileInfo.obj?.name}</Col>
            </Row>
            <Row  style={{ textAlign: 'left' }}>
                <Col span={6}> envoyer par :</Col> <Col span={12}>{props.isModalOpenFileInfo.obj?.sender_username}</Col>
            </Row>
            <Row  style={{ textAlign: 'left' }}>
            <Col span={6}> date envoyer :</Col> <Col span={12}>{props.isModalOpenFileInfo.obj?.date_sender}</Col>
            </Row>
            <Row  style={{ textAlign: 'left' }}>
            <Col span={6}> poids :</Col> <Col span={12}> {props.isModalOpenFileInfo.obj?.size}</Col>

            </Row>

        </Modal >

    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalShowImagePunchInformation);