import store from './store/store';
import './App.css';
import React from "react";
import HomePage from './pages/Home';
import TchatPage from './pages/Tchat';
import Contact from './pages/Contact';
import Price from './pages/Price';
import About from './pages/About';
import HomeAdmin from './pages/admin/Home'
import './sass/theme.scss';

import { Provider } from 'react-redux';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/tchat/:room"><TchatPage /></Route>
          <Route path="/price"><Price /></Route>
          <Route path="/about"><About /></Route>
          <Route path="/contact"><Contact /></Route>
          {/* admin section */}
          <Route path="/admin/home"><HomeAdmin /></Route>
          
          <Route exact path="/"><HomePage /></Route>
          <Route path="*"><NotFound /></Route>
        </Switch>
      </Router>
    </Provider>
  );
}

function NotFound() {
  return <h2>Not Found</h2>;
}

export default App; 