import { useState, useEffect, useRef, forwardRef,useContext } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';

import { SocketContext } from '../../SocketContext';

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Player = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

   const socket = useContext(SocketContext);
   const [shoutcast, setShoutcast] = useState(null);
   const [update, forceUpdate] = useState(new Date()); 

   useEffect(() => {
      if (socket) {
         socket.on('updateShoutcast', updateShoutcast);

      }
      setShoutcast(props.room)
      //ecouter une evenement socket io appeller une fonction de rappelle 
   }, [socket]);

   const updateShoutcast = (data) => {
      forceUpdate(new Date())
   }

   return (
      <iframe key={update} src={`https://player.radiopromo.ca/v6local.php?id=${shoutcast}`} frameBorder="0" allowtransparency="true" width="100%" height="100%" scrolling="no" />
   )
})


const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Player);