import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
export const SocketContext = createContext();
export const SocketProvider = ({ children , ...props }) => {

    const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;
    const [socket, setSocket] = useState(null);

    useEffect(() => {
     
        const token  = props.user?.token
        const newSocket = io(
            URL,
            {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 3,
                credentials: false,
                auth: {
                    token: token,
                  },
            },
        );
        setSocket(newSocket);
       
        return () => {
            newSocket.disconnect();
        };
    }, [props.user?.token]);


    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(SocketProvider);