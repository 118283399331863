import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Avatar, Switch, Input, Image } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { adminGetAllPunchImageApi ,adminPunchSetActiveImageApi } from '../../../../endpoints/admin'
import ModalShowImagePunchInformation from './ModalShowImagePunchInformation';
const { Column, ColumnGroup } = Table;

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const DeleteImagePunch = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    
    return (
        <>
        hello world
        </>
    ); 
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DeleteImagePunch);