import { useState, useEffect, forwardRef } from 'react';
import { Modal, Row, Col, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';
import { adminUpdateShoutcastApi } from '../../../../endpoints/admin';
import ModalShowRespondFromApi from './ModalShowRespondFromApi';

const ModalShowShoutcastInformation = forwardRef(({ user, setIsModalOpenFileInf, isModalOpenFileInfo }, ref) => {

    const [audioRef, setAudioRef] = useState(null);
    const [ip, SetNewIp] = useState(null)
    const [audioKey, setAudioKey] = useState(0);
    const [modalRespondApi, setModalRespondApi] = useState(false)
    const [message , setMessage] = useState(null)


    useEffect(() => {
        SetNewIp(isModalOpenFileInfo.obj?.ip)
    }, []);

    useEffect(() => {
        // Assurez-vous que la référence à l'élément audio est définie
        if (audioRef) {
            // Démarrer ou arrêter la lecture en fonction de l'état de lecture (play)
            if (isModalOpenFileInfo.show) {
                audioRef.play();
            } else {
                audioRef.pause();
            }
        }
        return () => {
            // Nettoyer et arrêter la lecture lorsque le composant est démonté
            if (audioRef) {
                audioRef.pause();
                audioRef.currentTime = 0;
            }
        };
    }, [isModalOpenFileInfo.show]);

    const handleOk = () => {
        setIsModalOpenFileInf({ show: false, obj: null });
    };

    const handleCancel = () => {
        setIsModalOpenFileInf({ show: false, obj: null });
    };
    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        SetNewIp(inputValue)
    }

    const updateShoutcast = () => {

        //update shoutcast new ip 
        adminUpdateShoutcastApi(user?.token, user?.room, user?.socketId, ip, isModalOpenFileInfo.obj?.id).then((resp) => {
          
            if(resp.permission){
                setAudioKey(prevKey => prevKey + 1);
                console.log(resp)
            }
            else{
                setMessage(resp.message)
                setModalRespondApi(true)
            }
        });

    }

    return (
        <>
            <ModalShowRespondFromApi message={message} modalRespondApi={modalRespondApi} setModalRespondApi={setModalRespondApi} />
            <Modal title="Informations sur le Shoutcast" style={{ textAlign: 'center' }} visible={isModalOpenFileInfo.show} onOk={handleOk} onCancel={handleCancel} destroyOnClose>

                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}> Écouter :</Col>
                    <Col span={12}>
                        <audio key={audioKey} ref={(audio) => setAudioRef(audio)} id={`audio-${ip}`} controls>
                            <source src={ip ? ip : isModalOpenFileInfo.obj?.ip} type="audio/mpeg" />
                        </audio>
                    </Col>
                </Row>


                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}> Ip :</Col>
                    <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
                        <Input onChange={handleInputChange} defaultValue={isModalOpenFileInfo.obj?.ip} placeholder="Basic usage" />
                        <Button onClick={updateShoutcast}>Update</Button>
                    </Col>
                </Row>


                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}> nom :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.name}</Col>
                </Row>

                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}> envoyer par :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.senderName}</Col>
                </Row>

                <Row style={{ textAlign: 'left' }}>
                    <Col span={6}> date envoyer :</Col>
                    <Col span={12}>{isModalOpenFileInfo.obj?.date}</Col>
                </Row>

            </Modal>
        </>
    );
});

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(ModalShowShoutcastInformation);


