import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Modal, Menu } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../../action/action';
import { PlayCircleOutlined ,AppstoreOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

const items = [
  {
    label: 'Gestion Utilisateur',
    key: 'user',
    icon: <UserOutlined />,
  },
  {
    label: 'Room',
    key: 'Room',
    icon: <AppstoreOutlined />,

  },
  {
    label: 'Role',
    key: 'Role',
    icon: <AppstoreOutlined />,

  },
  {
    label: 'Design',
    key: 'Design',
    icon: <AppstoreOutlined />,

  },

  {
    label: 'Punch',
    key: 'Punch',
    icon: <AppstoreOutlined />,

  },
  {
    label: 'Shoutcast',
    key: 'Shoutcast',
    icon: <PlayCircleOutlined />,

  },

  {
    label: (
      <a href="https://radiopromo.ca/" target="_blank" rel="noopener noreferrer">
        Annuaire webradio
      </a>
    ),
    key: 'alipay',
  },
];


const MenuAdmin = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

  const [current, setCurrent] = useState('user');

  const onClick = (e) => {
    setCurrent(e.key);
    props.setCurrentComponentObj({ name: e.key })
  };

  return (
    <div style={{overflowX:'auto', whiteSpace:'nowrap'}}>
      <Menu selectedKeys={[current]}
        mode="horizontal"
        defaultSelectedKeys={[current]}
        style={{
          borderRadius: '15px',
          color: 'black'
        }}
        onClick={onClick} items={items}
      />
    </div>
  )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(MenuAdmin);
