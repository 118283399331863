import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../action/action';
import { useParams } from "react-router-dom";
import { SocketProvider } from '../components/tchat/SocketContext';
import Login from '../components/tchat/Login'
import InterfaceChat from '../components/tchat/ChatInterface';
import Register from '../components/tchat/Register'
import Recover from '../components/tchat/Recover'
import ChooseRoom from '../components/tchat/SelectRoom'
import { axios } from '../endpoints/axios_custom'


const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Tchat = ({ app, testStore, designPage, user, ...props }) => {

    const { room } = useParams();
    const roomExist = useRef(false);
    let fpHash = useRef(null)
    useEffect(() => {
            const setFp = async () => {
                const fp = await FingerprintJS.load();
                const { visitorId } = await fp.get();
                fpHash.current = visitorId ;

                props?.dispatch(setUserApp({
                    InitUserState: 'login',
                    login: false,
                    username: null,
                    password: null,
                    ip: null,
                    email: null,
                    room: props.room,
                    selectedRoom: '0',
                    tab: [{}],
                    messageFromServeur: null,
                    disableVideo: false,
                    disableAudio: false,
                    userlist: true,
                    fpHash: fpHash.current
                }))
              await testRoom(room)
            };
            setFp();
      
    }, [])

    const testRoom = async (room) => {
        try {
            const resp = await axios.post(`/testroom`, { room: room , fingerprint : fpHash.current })

            if (resp?.data?.msg) {
            
                props?.dispatch(setUserApp({
                    InitUserState: 'login',
                    roomInfos: resp.data.room_infos,
                }))
            } else {
                props?.dispatch(setUserApp({
                    InitUserState: 'ChatNotFound'
                }))
            }
        } catch (error) {
            /*
            alert('not ready')

            setTimeout(() => {
                window.location.reload();
            }, 2000);
            // Gérer les erreurs ici
*/
            console.error(error);
        }
    }

    if (!room) {
        const error = `le systeme a détecter une erreur dans votre navigateur .-------------- error: roomId not found `
        return (
            <div>
                {room}
                erreur
            </div>
        )
    }
    switch (user?.InitUserState) {
        case 'login':
            return (
                <div style={{ backgroundColor: 'black', height: '100vh',maxHeight:'100vh', backgroundImage: `url(${URL}/uploads/intro.jpg)` }}>
                    <Login room={room} />
                    <div style={{ position: 'absolute', bottom: '0' }} >Numéro d'identification: {room}
                    </div>
                    <div style={{ position: 'absolute', bottom: '20px' }} >FPHASH: {user?.fpHash}
                    </div>
                </div>
            )
            break;

        case 'register':
            return (

                <div style={{ backgroundColor: 'black', height: '100vh',maxHeight:'100vh', backgroundImage: `url(${URL}/uploads/intro.jpg)` }}>
                    <Register room={room} />
                    <div style={{ position: 'absolute', bottom: '0' }} >Numéro d'identification: {room}
                    </div>
                </div>
            )
            break;

        case 'ChatNotFound':
            return (
                <div style={{ backgroundColor: 'black', height: '100vh',maxHeight:'100vh' }}>ChatNotFound</div>
            )
            break;

        case 'recover':
            return (
                <div style={{ backgroundColor: 'black', height: '100vh',maxHeight:'100vh', backgroundImage: `url(${URL}/uploads/intro.jpg)` }}>
                    <Recover room={room} />
                    <div style={{ position: 'absolute', bottom: '0' }} >Numéro d'identification: {room}
                    </div>
                </div>
            )
            break;

        case 'setRoom':
            return (

                <div style={{ backgroundColor: 'black', height: '100vh',maxHeight:'100vh', backgroundImage: `url(${URL}/uploads/intro.jpg)` }}>
                    <ChooseRoom room={room} />
                    <div style={{ position: 'absolute', bottom: '0' }} >Numéro d'identification: {room}
                    </div>
                </div>
            )
            break;

        case 'tchat':
            return (
                <SocketProvider {...{ user, ...props }}>
                    <InterfaceChat room={room} />
                </SocketProvider>
            )
    }
}

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(Tchat);
