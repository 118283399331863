import { axios } from '../../endpoints/axios_custom'
import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../action/action';
import { Form, Input, Button, message, Space, Typography, Col, Row } from "antd";
const { Title } = Typography;
var platform = require('platform');

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Recover = ({ app, testStore, designPage, user, ...props }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const [messages, setMessage] = useState('aucun');

    const success = (messagev) => {
        messageApi.open({
            type: 'success',
            content: messagev,
            style: {
                marginTop: '20vh',
            }
        });
    };

    const warning = (messagev) => {
        messageApi.open({
            type: 'danger',
            content: messagev,
            style: {
                marginTop: '20vh',
            }
        });
    };


    const onFinish = async (values) => {
        values.room = props.room
        const email = values.email
        values.platform = platform;
        setLoading(true);

        const resp = await axios.post(`${URL}/recover`, values, {
            withCredentials: true
        });

        if (resp.data.response) {
            success(resp.data.detail)
            
                setLoading(false);
                props?.dispatch(setUserApp({ InitUserState: 'login' }))
           
        }
        else {
            warning(resp.data.detail)
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Échec de la soumission du formulaire:", errorInfo);
    };

    const setLogin = () => {
        props?.dispatch(setUserApp({ InitUserState: 'login' }))
    }

    return (
        <>
         <Row style={{ backgroundColor: '#010103' }}>
                <Col span={24}> <Title level={3} style={{margin:12, textAlign: 'center', color: ' rgb(248 248 248)' }}>VIDEOCHAT RADIO</Title></Col>
            </Row>
            <div
                    style={{
                    height:'80%',
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center'
                }}
            >
                <div style={{ padding: '60px', border: ' 0.5px solid', borderRadius: '16px' ,backgroundColor:'black' }}>
                    <div >
                        <Form
                            name="loginForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            labelCol={{ span: 12 }}
                        >

                            <Form.Item
                                labelAlign="left"
                                label={<span style={{ color: 'white' }}>Adresse e-mail</span>}
                                name="email"
                                rules={[
                                    { required: true, message: 'Veuillez saisir votre adresse e-mail!' },
                                    { type: 'email', message: 'Veuillez saisir une adresse e-mail valide!' },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                                <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
                                    Récupérer
                                </Button>
                            </Form.Item>

                            <Col span={24} style={{ justifyContent: 'center', fontSize: '13px' }}>

                                <span onClick={setLogin} style={{ color: 'white', fontSize: '13px' }}>Déja Inscrit ? <bold style={{ color: 'orange' }}>Se connecter</bold></span>
                            </Col>
                        </Form>
                        {contextHolder}
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(Recover);
