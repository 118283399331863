import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { NotificationOutlined, SoundOutlined, MenuUnfoldOutlined, VideoCameraAddOutlined, AudioOutlined, DesktopOutlined, ApiOutlined, SettingOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { } from '@chatscope/chat-ui-kit-react';
import { Col, Row, Typography, Radio, Button, Modal } from 'antd';
import { SocketContext } from '../../SocketContext';
import { playAudioGift } from '../../chatUtils/SoundSysteme'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Gift = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);

    const showModalGift = () => {
        props.setIsModalOpenGift(true);
    };
    const handleOkGift = () => {
        props.setIsModalOpenGift(false);
    };
    const handleCancelGift = () => {
        props.setIsModalOpenGift(false);
    };

    useEffect(() => {
      
        if (props.isModalOpenGift && ref.SoundEffect.current && props.gift.image) {
            playAudioGift(`${URL}/systeme/image/gift/wav/${props.gift.sound}`)
        }
    }, [props.isModalOpenGift, props.gift?.sound])

    return (
        <>
            {
                props.gift?.image &&
                <Modal style={{ textAlign: 'center' }} footer={[<Button onClick={handleOkGift}>Ok</Button>]} width={400} title={
                    <>
                        Félicitations, vous avez reçu un cadeau de : <strong>{props.gift.sendFromUsername}</strong> !
                    </>
                } open={props.isModalOpenGift} >
                    <div style={{ textAlign: 'center' }}><img src={`${URL}/systeme/image/gift/${props.gift.image}`} alt="beers" width={200} height={200} /></div>
                </Modal>
            }

        </>
    )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Gift);
