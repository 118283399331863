import { setUserApp } from "../../../action/action";
import sendRequest from "../../socket/requestModule";

export const join_room = async (props , fpHash) => {
  
    //store socket id in redux
    props?.dispatch(setUserApp({
        socketId: props.socket.id,
    }))

    sendRequest(props.socket, 'join_room', { username: props.user?.username, roomId: props.user?.selectedRoom, room: props.room , fingerPrint: fpHash })
        .then(async response => {

            setTimeout(async () => {
                //auto open cam on join room
                //await enableLocalVideo()
            }, 5000);
        })
        .catch(error => {
            // to do
            alert(error + 'error');
        });
}