import { useState, useEffect, forwardRef } from 'react';
import { Modal, Row, Col, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { CheckOutlined } from '@ant-design/icons';
import { adminUpdateShoutcastApi } from '../../../../endpoints/admin';

const ModalShowRespondFromApi = forwardRef(({ user, ...props }, ref) => {


    const handleCancel = () => {
        console.log(props)
       props.setModalRespondApi(false)
    }

    const handleOk = () => {
      props.setModalRespondApi(false)
    }

    return (
        <Modal title="Réponse du serveur" style={{ textAlign: 'center' }} visible={props.modalRespondApi} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
            <Row style={{ textAlign: 'left' }}>
             
                <Col style={{textAlign:'center'}} span={24}>
                   {props.message}
                </Col>
            </Row>
        </Modal>
    );
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(ModalShowRespondFromApi);
