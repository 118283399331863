import { useEffect, Fragment, forwardRef, useContext } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { SocketContext } from '../../SocketContext';
import sendRequest from '../../../socket/requestModule'
import { useState } from 'react';
import { getActivePunch } from '../../../../endpoints/punch'

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const Banner = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {
   let socket = useContext(SocketContext);
   const [imagePunch, setImagePunch] = useState({file_name:null})
   useEffect(() => {

      if(user?.token){
      
            getPunchInfo();
        

      }
      if (socket) {
         socket.on('updatePunchImage', updatePunch)
         return () => {
            socket.off('updatePunchImage', updatePunch)
         }
      };
   }, [socket]);

   const updatePunch = (punch) => {
      setImagePunch(punch)
   }

   const getPunchInfo = async () =>{
      if(user?.token){
       await getActivePunch(user?.token, user?.room).then(punch => {
             setImagePunch(punch.punch)
           })
      } 
   }

   return (
      imagePunch?.file_name ?
      <img id="img_punch" style={{ width: '100%', height: '100%' }} src={`${URL}/uploads/${imagePunch.file_name}`}/>
      :
      <div>aucune image  active</div>
   )
})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Banner);

