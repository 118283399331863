import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { connect } from 'react-redux';
import { actionMessage } from '../../../../reducer/reducer';
import * as React from 'react';

const ListRoom = ({ app, testStore, designPage, user, ...props }) => {

    const roomCloseModalRef = {}
    const roomOpenModalRef = {}

    const handlerChooseRoom = (indexRoom) => {
        props?.dispatch(setUserApp({ actionChangeRoomIndex: Number(indexRoom) }));
    }

    const ReturnWordRoom = () =>{
        if(user?.roomList){
         return Object.keys(user?.roomList).length < 1 ?  'salon' : 'salons'
        }
       else{
        return null
       }
    }

    const ReturnCountRoom = () =>{
       return user?.roomList ?  Object.keys(user?.roomList).length : 0
    }

    const show_hide = (id) => {
        const openModal = roomOpenModalRef[id]
        const closeModal = roomCloseModalRef[id]
        openModal.showModal()
        closeModal.addEventListener("click", () => {
            openModal.close();
        }, { passive: true });
    }

    return (
        <>
        <span className="whiteBadge badge badge-secondary"><ReturnCountRoom /></span>
            <span onClick={() => show_hide(2)} style={{cursor: 'pointer'}}><ReturnWordRoom /></span>
            <div>
                <div className="close-modal" ref={ref => roomCloseModalRef[2] = ref}>
                    <dialog id="modal-dropdown-list-" ref={ref => roomOpenModalRef[2] = ref} className="modal" open="">
                        <div className="content-modal-list-users">
                            <ul> <div style={{textAlign:'center'}}>Liste des salons</div>
                                {
                                    user?.roomList ?
                                        user?.roomList.map((obj,key) => (
                                            <li onClick={() => handlerChooseRoom(Number(obj.indexRoom))}><a href="#">{obj.title}</a> -  <span className="whiteBadge badge badge-secondary">0</span></li>
                                        ))
                                        :
                                        <div>rien</div>
                                }
                            </ul>
                        </div>
                    </dialog>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(ListRoom);