import { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';


const FlashMessageFromServer = ({ user, ...props }) => {



  return (
    <div style={{color:'white', position: 'absolute', bottom: '90px' ,textAlign:'center', width:"50%",textAlign:'center',left:'10%' }}>
      { <p ref={props.flashMessageRef} ></p>}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({ user })
export default connect(mapStateToProps)(FlashMessageFromServer);
