import { useState, useEffect, useRef, forwardRef,useContext } from 'react';

const PlayerApi = (props) => {
   const [shoutcast, setShoutcast] = useState(null);

   return (
      <iframe src={`https://player.radiopromo.ca/v6local.php?id=${props.room}`} frameBorder="0" allowtransparency="true" width="100%" height="100%" scrolling="no" />
    );
    
}


export default PlayerApi ;