import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp, setDesignPage } from '../../../../action/action';
import { Avatar } from '@chatscope/chat-ui-kit-react';
import { SocketContext } from '../../SocketContext';
import { Form, Input, Button, message, Space, Typography, Col, Row, Menu, Modal } from "antd";
import { VideoCameraAddOutlined, TrademarkCircleOutlined, TeamOutlined, LockOutlined, AudioOutlined, HomeOutlined, ExportOutlined, ApiOutlined, SettingOutlined } from '@ant-design/icons';
import { adminGetAllRoomApi } from '../../../../endpoints/admin'

const { Text, Link } = Typography;
const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;
const imagePath = URL

const ModalPasswordRoom = ({ app, testStore, designPage, user, ...props }) => {

    const IdRoomToJoin = useRef(null)
    const socket = useContext(SocketContext);
    const [password, setPassword] = useState('');
    const isButtonDisabled = password === '';
    const [antModal, setAntModal] = useState(false);
    const [antModalPasswordRoom, setAntModalPasswordRoom] = useState(false)
    const [updateComponent, setUpdateCOmponent] = useState()
    const [rooms, setRoom] = useState([])

    useEffect(() => {
        if (socket) {
            socket.on('updateRoomInfos', (obj) => updateInfosRoom(obj));
        }
        return () => {
            if (socket) {
                socket.off('updateRoomInfos', (obj) => updateInfosRoom(obj))
            }
        };
    }, [socket]);

    useEffect(() => {
        if (props.antModalRooms) {
            adminGetAllRoomApi(user?.token, user?.room, user?.socketId).then(data => {
                setRoom(data.room);
            });
        }
    }, [props.antModalRooms]);

    const updateInfosRoom = (obj) => {
      
        if (obj) {
            setRoom(obj)
        }
    }

    const handleOk = () => {
        if (socket) {
            socket.emit('joinNewRoom', IdRoomToJoin.current, password)
            IdRoomToJoin.current = ''
        }
        setAntModal(false);
        setPassword('')
    };

    const handleCancel = () => {
        setAntModal(false);
        setPassword('')
    };

    const handlerEnterRoom = (id, password) => {
        IdRoomToJoin.current = id
        if (password !== '') {
            setAntModal(true);
        }
        else {
            if (socket) {
                socket.emit('joinNewRoom', id, password)
                IdRoomToJoin.current = ''
            }
        }
    }
    const alreadyInRoom = (id) => {
        return user?.tab?.some((room) => room.id === id);
    }

    const closeAntRoomModal = () => {
        props.setAntModalRooms(false)
        setRoom([])
    }

    const handleOkRoomModal = () => {
        props.setAntModalRooms(false)
        setRoom([])
    }

    return (
        <>
            <Modal
                title="Saisissez le mot de passe"
                open={antModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: isButtonDisabled, // Désactiver le bouton OK si la case est vide
                }}
            >
                <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                />
            </Modal>

            <Modal   width={'80%'} title="Choisir une room" open={props.antModalRooms} onOk={handleOkRoomModal} onCancel={closeAntRoomModal}>
                <div>
                  
                    <div  style={{ padding: '10px', border: ' 0.5px solid', borderRadius: '16px' }}>

                        {Array.isArray(rooms) && rooms.map((room, index) => {
                            return (
                                <Row key={room.id} style={{ color: 'black', backgroundColor: 'white', padding: '5px' }}>
                                    <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar shape="square" src={`https://localhost:3001/uploads/${room.logo}`} />
                                    </Col>
                                    <Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Text strong>{room.title}</Text>
                                    </Col>
                                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Text>{room.description}</Text>
                                    </Col>
                                    <Col span={3} style={{ display: 'flex', alignItems: 'center' }}>
                                        {room.total} / {room.capacity_max}
                                    </Col>
                                    {room.password !== '' ?
                                        <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>
                                            <LockOutlined />
                                        </Col>
                                        :
                                        <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>
                                        </Col>
                                    }
                                    <Col span={6}>
                                        <Button disabled={alreadyInRoom(room.id)} onClick={() => handlerEnterRoom(room.id, room.password)} style={{ width: "100%" }} type="primary">Entrer</Button>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(ModalPasswordRoom);