import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Col, Row, Space, Table, Tag, Modal, Switch, Input, Image } from 'antd';
import { AndroidOutlined, AppleOutlined, ConsoleSqlOutlined, SettingOutlined } from '@ant-design/icons';
import { ConversationList, TypingIndicator, MessageInput, Avatar, MessageList, Message, MessageSeparator, Conversation, ConversationHeader, Sidebar } from '@chatscope/chat-ui-kit-react';
import { connect } from 'react-redux';
import InformRoomUserEntrance from './InformRoomUserEntrance';
import MessageFromSysteme from './MessageFromSysteme';
import MessageFromBot from './MessageFromBot';
import ModalEditMessage from './ModalEditMessage';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { LinearGradient, RadialGradient } from 'react-text-gradients'

import TimeAgo from 'react-timeago';

import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(frenchStrings)

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const MessageFromUser = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    // Custom formatter to display only minutes and not show seconds
    const customFormatter = buildFormatter({
        minute: (value, unit, suffix) => {
            return `${value} minute`;
        },
        minutes: (value, unit, suffix) => {
            return `${value} minutes`;
        },
        second: () => '-1 minute', // Fallback to 1 minute if seconds are not present
        seconds: () => '-1 minute', // Fallback to 1 minute if seconds are not present
    });


    const [editMessage, SetEditMessage] = useState(false)
    const messageToEdit = useRef(null)
    const [messageServeur, setMessageServeur] = useState(null)

    const [currentTime, setCurrentTime] = useState(Date.now());

    const OpenModaleditUserMessage = (obj) => {
        SetEditMessage(true)
        messageToEdit.current = obj
    }
    const closeModal = () => {
        SetEditMessage(false);
        setMessageServeur(null)
    };

    const timeStyles = {
        color: ref.templateTexteColorState.conversationMoment,
        // Ajoutez d'autres styles au besoin
    };

    useEffect(() => {
        let animationFrameId;
    
        function animate(timestamp) {
            setCurrentTime(Date.now());
            animationFrameId = requestAnimationFrame(animate);
        }
    
        // Démarrer l'animation
        animationFrameId = requestAnimationFrame(animate);
    
        // Nettoyer le frame d'animation lors du démontage du composant
        return () => cancelAnimationFrame(animationFrameId);
    }, [setCurrentTime]);
    
    // Utiliser un intervalle pour mettre à jour le temps toutes les 60 secondes
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(Date.now());
        }, 60000); // Mettre à jour toutes les 60 secondes
    
        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div key={props.index + 1} style={{ display: 'flex', justifyContent: 'flex-start' }} >

            <div key={props.index + 2} style={{ flex: '0 0 97%' }}>
        {/*}   <MessageFromBot ref={ref}/> {*/}
                {props.message.type == 'messageBuyChat' ? <MessageFromSysteme ref={ref} /> :

                    props.message.type == 'messageSysteme' ? <InformRoomUserEntrance ref={ref} message={props.message} /> :
                        <Conversation
                            style={{ backgroundColor: ref.templateTexteColorState.conversationActive, cursor: 'auto' }}
                            key={props.index}
                            name={<span style={{ color: props.message.roleColor }}>
                                {props.message.from}
                                <span style={{paddingLeft:'5px',fontSize:'10px'}}>
                                    <TimeAgo style={timeStyles} date={props.message.time} formatter={customFormatter} live={true} locale="fr" />
                                </span>


                            </span>}
                            lastSenderName={props.message.username}

                            info={/^https?:\/\/.*\.(bmp|gif|jpe?g|png|svg|tiff?|webp)$/i.test(props.message.message)
                                ?
                                <Image src={props.message.message} alt="Image" width={150} />
                                :

                                <div className='test' style={{
                                    fontWeight: props.message?.formatMessage?.bold ? 'bold' : 'normal',
                                    fontStyle: props.message?.formatMessage?.italic ? 'italic' : 'normal',
                                    textDecoration: props.message?.formatMessage?.underline ? 'underline' : 'none',
                                    color: props.message?.formatMessage?.colorText,
                                    fontSize: '15px'
                                }}>
                                    <span style={{ padding: '4px' }}>
                                        {props.message.message}
                                    </span>
                                </div>
                            }
                            lastActivityTime={
                                null
                            }
                        >
                            <Avatar
                                src={`${URL}/${props.message.avatar}`}
                                name={'props.message.username'}
                                status={props.message.status}
                            />
                        </Conversation>
                }

            </div>


            {user?.role?.ban_user === 1 && props.message.type !== 'messageSysteme' &&

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => OpenModaleditUserMessage(props.message)}>
                        <SettingOutlined style={{ color: ref.templateTexteColorState.conversationModifyTexte }} />
                    </span>
                </div>
            }

            {editMessage &&
                <ModalEditMessage messageToEdit={messageToEdit} editMessage={editMessage} closeModal={closeModal} />
            }
        </div>

    );

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MessageFromUser);