import { useState, useEffect, useRef, Fragment, useContext, forwardRef } from 'react';
import { Button, Layout, Menu, theme, Badge, Col, Row, Dropdown, Space, Popover } from 'antd';
import { MenuUnfoldOutlined, VideoCameraOutlined, MenuFoldOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp } from '../../../../action/action';
import { TeamOutlined, HomeOutlined, DeleteOutlined } from '@ant-design/icons';

import { Avatar } from '@chatscope/chat-ui-kit-react';
import Liste from '../rightSide/Liste'

const { Header, Sider, Content } = Layout;

const UserList = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => 
{
    const [collapsed, setCollapsed] = useState(true);

    const [sliderWidth, setSliderWidth] = useState();
    const [intialWidth ,setIntialWidth] =useState();

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        setSliderWidth(0);
    };

    useEffect(()=>{

        toggleCollapsed()
    },[user.userlist])

    useEffect(()=>{
        if(document.body.clientWidth < 800 ){
            setIntialWidth(200);
        }
        else{
            setIntialWidth(300);
        }
       
    },[])

    return (
        <>
            <Sider style={{backgroundColor:'transparent'}} ref={ref.zoneUserList} width={intialWidth} collapsedWidth={sliderWidth}  collapsed={collapsed} onCollapse={setCollapsed} >
                <Liste ref={ref} startVideoProducer={props.startVideoProducer} flashMessageRef={props.flashMessageRef} />
            </Sider>
        </>
    )

})

const mapStateToProps = ({ user }) => ({ user });
const mapDispatchToProps = dispatch => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserList);
