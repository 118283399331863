import { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { setInitApp, setUserApp, setMessageApp, setDesignPage } from '../../../action/action';
import { SocketContext } from '../SocketContext';
import { Form, Input, Button, message, Space, Typography, Col, Row, Menu, Modal } from "antd";

const URL = process.env.REACT_APP_MODE_ENV === "development" ? process.env.REACT_APP_URL_SERVER_DEV : process.env.REACT_APP_URL_SERVER_PROD;

const RefControler = forwardRef(({ app, testStore, designPage, user, ...props }, ref) => {

    const socket = useContext(SocketContext);


  
    const ApplyColortemplate = (data) => {

        let stop = null
        Object.keys(data[0]).forEach(key => {

            if (key === 'subscription_level') {
                ref.subscription_level.current = data[0][key]
            }

            /* sound template room */
            if (key === 'soundEntrance') {
                ref.entranceMp3.current = data[0][key]
            }

            if (key === 'soundExit') {
                ref.exitMp3.current = data[0][key]
            }

    
            /* sound template room */

            //texte colors
            if (key === 'conversationMessage' ||
                key === 'conversationActive' ||
                key === 'conversationMoment' ||
                key === 'conversationModifyTexte'

            ) {
                applyTexteColor(key, data[0][key])
                stop = true
            }
            else {
                stop = false
            }

            //template zone colors 
            let value
            if (data[0].hasOwnProperty(key) && !stop) {

                try {
                    value = JSON.parse(data[0][key]);
                }
                catch (err) {
                    // console.log(err)
                }

                const elementRef = ref[key]?.current;
                if (elementRef && key !== 'subscription_level') {
                    //on reset le ref
                    elementRef.style.backgroundImage = 'none';
                    elementRef.style.backgroundColor = 'transparent';
                    if (value) {
                        if (value.type === 'simpleColor') {
                            elementRef.style.backgroundColor = value.valueForType;
                        } else if (value.type === 'colorWithDegrade') {
                            const cssCouleur = `linear-gradient(to right, rgb(${value.valueForType.r}, ${value.valueForType.g}, ${value.valueForType.b}), rgba(0, 0, 0, 0))`;
                            elementRef.style.backgroundImage = cssCouleur;
                        } else if (value.type === 'file') {
                            elementRef.style.backgroundImage = `url(${URL}/${value.valueForType})`;
                        } else if (value.type === 'texte') {
                            alert(value.valueForType);
                            elementRef.style.backgroundColor = value.valueForType;
                        }
                    }
                }
            }
        });
    }

    const applyTexteColor = (key, value) => {
        ref.setTemplateTexteColorState(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    useEffect(() => {
        if (socket) {
            socket.on("template", ApplyColortemplate)
                ;
            return () => {
                socket.off("template", ApplyColortemplate);
            }
        };
    }, [socket]);

})

const mapStateToProps = ({ app, testStore, designPage, user }) => ({ app, testStore, designPage, user });
const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RefControler);