import imageCompression from 'browser-image-compression';

export const compressImage = (file) => {
    return new Promise((resolve, reject) => {

        let sizeOfImage = 99999999999999;

        const data = file
        if (data.type === 'image/png' || data.type === 'image/jpeg' || data.type === 'image/webp') {
            const fileName = data.name
            const compress = async data => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    //message reader.error
                }
                await imageCompression(data, { maxSizeMB: 1, useWebWorker: true }).then(res => {
                    if ((res.size / 1024).toFixed(0) === sizeOfImage && sizeOfImage > 400) {
                        //retourne un message d'erreur
                    }
                    if (sizeOfImage >= 400) {
                        res.name = fileName
                     
                        resolve(res);
                     
                    } else {
                        reader.readAsDataURL(res)
                    }
                    sizeOfImage = (res.size / 1024).toFixed(0);
                })
            }
            compress(data)
           
        }
        else if (data.type === 'image/gif') {
            resolve(data);
         
        }
        else {
          //  props?.dispatch(setMessageApp({ type: 'error', message: 'fichier refuser', notView: true }));
        }

      });
}

